export const ACTION_GUEST_URL = {
  LOAD_ITEMS: 'GUEST_URL/LOAD_ITEMS',
  LOAD_ITEM: 'GUEST_URL/LOAD_ITEM',
  ADD: 'GUEST_URL/ADD',
  UPDATE: 'GUEST_URL/UPDATE',
  REFRESH_TOKEN: 'GUEST_URL/REFRESH_TOKEN',
  DELETE: 'GUEST_URL/DELETE',
  SET_ITEMS: 'GUEST_URL/SET_ITEMS',
  SET_NEW_ITEM: 'GUEST_URL/SET_NEW_ITEM',
  CLEAR: 'GUEST_URL/CLEAR',
  SHOW_EXPIRED: 'SHOW_EXPIRED',
}
