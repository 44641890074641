
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class AppLogo extends Vue {
  gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
