
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IUser } from '@/components/user/user-model'

import stc from 'string-to-color'

@Options({
  components: {},
})
export default class UserAvatar extends Vue {
  @Prop({ default: '' })
  user!: IUser

  @Prop({ default: 40 })
  size!: number

  @Prop({ default: false })
  square!: boolean

  stc = stc

  get user2CharFirst() {
    return this.user.displayName?.charAt(0)?.toUpperCase() || 'U'
  }
}
