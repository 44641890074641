import { taskFieldsDisplayInTable } from '@/constants/task-fields'
import dayjs from 'dayjs'

export const randomString = (length?: number) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  const n = length || 15
  for (let i = 0; i < n; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const formatDate = (date: string) => {
  if (date) {
    return dayjs(date).format('MMM D, h:mm A')
  }

  return ''
}

export const formatDateTime = (date: string) => {
  if (date) {
    return dayjs(date).format('MMM D, YYYY h:mm A')
  }

  return ''
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const isImage = (minetype: string) => {
  return minetype.indexOf('image') >= 0
}

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }

  return outputArray
}

export const isCustomField = (name: string) => {
  return !taskFieldsDisplayInTable().filter((item) => item.name === name).length
}
