<template>
  <CLoading v-if="loading" />
  <q-dialog v-model="visible">
    <q-banner v-if="isMissingForms" dense inline-actions class="text-white bg-primary">
      You need at least a FORM to create project!
      <template v-slot:action>
        <q-btn flat color="white" label="Manage form" @click="$goto('workspace_board.forms')" />
      </template>
    </q-banner>

    <q-card style="width: 500px" v-if="!isMissingForms">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef">
          <label class="text-grey-9 text-bold">Project name</label>
          <q-input
            lazy-rules
            :rules="[val => (val && val.length > 0) || requiredRule.message]"
            outlined
            v-model="form.title"
            dense
            placeholder="project name"
          />

          <label class="text-grey-9 text-bold">Description</label>
          <q-input type="textarea" outlined v-model="form.description" dense placeholder="project description" />

          <q-select
            option-value="_id"
            option-label="title"
            outlined
            dense
            options-dense
            v-model="form.workflowId"
            :options="workflows"
            label="Workflow"
            emit-value
            map-options
            :rules="[val => (val && val.length > 0) || requiredRule.message]"
            class="q-mt-md"
          />

          <q-select
            option-value="_id"
            option-label="title"
            outlined
            dense
            options-dense
            v-model="form.formId"
            :options="forms"
            label="Form"
            emit-value
            map-options
            :rules="[val => (val && val.length > 0) || requiredRule.message]"
          />
          <div class="q-gutter-sm">
            <label class="text-grey-9 text-bold">Color</label>
            <div>
              <ColorPickerDropdown v-model="form.color" />
            </div>
          </div>
          <q-space class="q-my-md" />

          <div class="q-gutter-sm text-right">
            <q-btn color="white" text-color="black" @click="onCancel">Cancel</q-btn>
            <q-btn color="primary" @click="onSubmitProject"> Save </q-btn>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { QForm } from 'quasar'
import { maska } from 'maska'
import { COLORS_LIST } from '@/constants/vars'
import { IProject, ProjectModel } from '@/components/project/project-model'
import { FormModel } from '../form/form-model'

import ProjectMixin from '@/components/project/mixins/ProjectMixin.vue'
import ColorPickerDropdown from '@/components/color/ui/ColorPickerDropdown.vue'
import logging from '@/utils/logging'
import FormMixin from '../form/mixins/FormMixin.vue'
import CLoading from '../common/ui/CLoading.vue'
import WorkflowMixin from '../workflow/mixins/WorkflowMixin.vue'
import { WorkflowModel } from '../workflow/workflow-model'

@Options({
  components: { ColorPickerDropdown, CLoading },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class ProjectFormModal extends mixins(ProjectMixin, FormMixin, WorkflowMixin) {
  @Prop({ default: {} })
  projectData!: ProjectModel

  @Prop()
  modalVisible!: boolean

  isComponentReady = false

  get loading() {
    return !this.isComponentReady || this.$store.state.project.loading
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get forms(): FormModel[] {
    return this.$store.getters.forms(this.workspaceId) || []
  }

  get workflows(): WorkflowModel[] {
    return this.$store.getters.workflows(this.workspaceId)
  }

  requiredRule = {
    message: 'This field is required',
  }

  form: IProject = {
    formId: '',
    workflowId: '',
    title: '',
    description: '',
    color: COLORS_LIST[0],
  }

  rules = {
    title: [this.requiredRule],
  }

  get isNew() {
    return !this.projectData?._id
  }

  get modalTitle() {
    return this.isNew ? 'Create new Project' : 'Edit project'
  }

  get isMissingForms() {
    return this.isComponentReady && !this.forms.length
  }

  onSubmitProject() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSaveProject()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSaveProject() {
    if (this.isNew) {
      return this.addProject(this.form)
    } else {
      return this.updateProject(this.form)
    }
  }

  async getFormsList() {
    if (this.forms.length) {
      return
    }

    return this.getForms()
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    this.form = { ...this.form, ...this.projectData?.sertialize() }
    await Promise.all([this.getFormsList(), this.getWorkflows()])

    this.isComponentReady = true
  }
}
</script>
