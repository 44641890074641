import { graphqlChangedHandler } from '@/utils/graphql'
import { IForm, FormModel } from '@/components/form/form-model'
import { ACTION_FORM } from './form-actions'
import FormApi from '@/components/form/form-api'

type TState = {
  items: Record<string, FormModel[]>
  item: FormModel | null
  loading: boolean
}

const initState: TState = {
  items: {},
  item: null,
  loading: false,
}

export default {
  state: initState,
  getters: {
    forms: (state: TState) => {
      return (workspaceId: string) => {
        return state.items[workspaceId] || []
      }
    },
    form: (state: TState) => {
      return state.item
    },
  },
  mutations: {
    // [Forms]
    [ACTION_FORM.SET_ITEMS]: (state: TState, payload: { items: FormModel[], workspaceId: string }) => {
      state.items[payload.workspaceId] = payload.items
    },
    [ACTION_FORM.SET_ITEM]: (state: TState, payload: { item: FormModel }) => {
      state.item = payload.item
    },
    [ACTION_FORM.SET_NEW_ITEM]: (state: TState, { item }: { item: FormModel }) => {
      if (!item.workspaceId) {
        throw new Error('Unknown form workspaceId')
      }

      state.items[item.workspaceId] = graphqlChangedHandler<FormModel>(state.items[item.workspaceId], item)
    },
    [ACTION_FORM.CLEAR]: (state: TState) => {
      state.items = initState.items
      state.loading = initState.loading
    },
  },
  actions: {
    [ACTION_FORM.LOAD_ITEMS]: async ({ commit }, payload: { workspaceId: string }) => {
      const resp = await FormApi.workspace(payload.workspaceId).list()
      if (resp?.length) {
        commit(ACTION_FORM.SET_ITEMS, { items: resp, workspaceId: payload.workspaceId })
      }
    },
    [ACTION_FORM.ADD]: async ({ commit }, payload: { form: IForm, workspaceId: string }) => {
      const item = await FormApi.workspace(payload.workspaceId).add(payload.form)
      if (item) {
        commit(ACTION_FORM.SET_NEW_ITEM, { item })
      }

      return item
    },
    // eslint-disable-next-line
    [ACTION_FORM.LOAD_ITEM]: async ({ commit }, payload: { workspaceId: string, id: string }) => {
      return await FormApi.single(payload.id)
    },
    [ACTION_FORM.UPDATE]: async ({ commit }, payload: IForm) => {
      const item = await FormApi.update(payload)
      if (item) {
        commit(ACTION_FORM.SET_NEW_ITEM, { item })
      }

      return item
    },
    [ACTION_FORM.DELETE]: async ({ commit }, payload: { id: string }) => {
      const item = await FormApi.delete(payload.id)
      if (item) {
        commit(ACTION_FORM.SET_NEW_ITEM, { item })
      }

      return item
    },
  },
}
