import BaseModel from '../../models/BaseModel'

export enum EWorkflowType {
  new = 'new',
  doing = 'doing',
  done = 'done',
}

export interface IWorkflowState {
  type: EWorkflowType
  label: string
  color: string
  value: string
  position: number
}

export interface IWorkflow {
  _id?: string
  workspaceId?: string
  title?: string
  description?: string
  states?: IWorkflowState[]
}

export class WorkflowModel extends BaseModel {
  _id?: string
  workspaceId?: string
  title?: string
  description?: string
  states?: IWorkflowState[]

  sertialize(): IWorkflow {
    return {
      _id: this._id,
      workspaceId: this.workspaceId,
      title: this.title,
      description: this.description,
      states: this.states,
    }
  }
}
