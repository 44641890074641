
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { UserModel } from '@/components/user/user-model'
import { ACTION_PROFILE } from '@/components/user/profile-actions'
import { errorHandler } from '@/utils/errorHandler'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'

@Options({
  components: { UserAvatar },
})
export default class AppTopbarProfile extends Vue {
  @Prop()
  miniState = false

  themeSelectVisible = false

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  logout() {
    try {
      this.$store.dispatch(ACTION_PROFILE.LOGOUT)
      this.$goto('login')
    } catch (error) {
      errorHandler(error)
    }
  }
}
