
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class SelectThemeModal extends Vue {
  @Prop()
  modalVisible!: boolean

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get allThemes() {
    return this.$theme.themes
  }

  get selectedTheme() {
    return this.$theme.selected()
  }

  set selectedTheme(val: string) {
    this.$theme.setTheme(val)
  }
}
