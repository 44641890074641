
// Apollo import
import { ApolloClient, InMemoryCache, split } from '@apollo/client/core'
import { createUploadLink } from 'apollo-upload-client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { APP_USER_STORE_KEY } from '@/constants/vars'
import { localStore } from '@/utils/localstore'
import store from '@/store'
export class AppApolloClient {
  getAuthToken = () => {
    return store?.getters?.guestAuthToken || localStore.getItem(APP_USER_STORE_KEY.AUTH_TOKEN)
  }

  linkClient = () => {
    const middlewareLink = setContext(() => {
      const token = this.getAuthToken()

      return {
        headers: {
          authorization: token ? `Bearer ${token}` : null,
        },
      }
    })

    const httpUploadLink = createUploadLink({
      uri: process.env.VUE_APP_API_ENDPOINT,
    })

    // eslint-disable-next-line
    const linkWithContext = middlewareLink.concat(httpUploadLink as any)
    // Create the apollo client
    return new ApolloClient({
      link: linkWithContext,
      cache: new InMemoryCache({
        addTypename: false,
      }),
      connectToDevTools: false,
    })
  }

  wsClient = () => {
    // Create the subscription websocket link
    const wsLink = new WebSocketLink({
      uri: process.env.VUE_APP_SUBSCRIPTION_API_ENDPOINT || '',
      options: {
        reconnect: true,
        connectionParams: {
          authorization: this.getAuthToken(),
        },
      },
    })

    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      },
      wsLink
    )

    // Create the apollo client
    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        addTypename: false,
      }),
    })
  }
}
