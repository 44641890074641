import cloneDeep from 'lodash/cloneDeep'
import TaskCommentApi from '@/components/comment/task-comment-api'
import { graphqlChangedHandler } from '@/utils/graphql'
import { TaskCommentModel, ITaskComment } from '@/components/comment/task-comment-model'
import { ACTION_TASK_COMMENT } from './task-comment-actions'

type TState = {
  items: TaskCommentModel[]
  item: TaskCommentModel | null
  loading: boolean
}

const initState: TState = {
  items: [],
  item: null,
  loading: false,
}

export default {
  state: initState,
  getters: {
    taskComments: (state: TState) => {
      return (taskId: string) => {
        return state.items[taskId] || []
      }
    },
  },
  mutations: {
    // [Comments]
    [ACTION_TASK_COMMENT.SET_ITEMS]: (state: TState, payload: { items: TaskCommentModel[], taskId: string }) => {
      state.items[payload.taskId] = payload.items
    },
    [ACTION_TASK_COMMENT.SET_NEW_ITEM]: (state: TState, { item }: { item: TaskCommentModel }) => {
      if (!item.taskId) {
        return
      }

      state.items[item.taskId] = graphqlChangedHandler<TaskCommentModel>(state.items[item.taskId], item)
    },
    // [Set selected comment]
    [ACTION_TASK_COMMENT.SET_ITEM]: (state: TState, { item }: { item: TaskCommentModel }) => {
      state.item = cloneDeep(item)
    },
    [ACTION_TASK_COMMENT.CLEAR]: (state: TState) => {
      state.item = initState.item
      state.items = initState.items
      state.loading = initState.loading
    },
  },
  actions: {
    [ACTION_TASK_COMMENT.LOAD_ITEMS]: async ({ commit, state }, payload: { taskId: string }) => {
      let comments = state.items[payload.taskId]
      if (!comments) {
        comments = await TaskCommentApi.task(payload.taskId).list()
      }

      if (comments) {
        commit(ACTION_TASK_COMMENT.SET_ITEMS, { taskId: payload.taskId, items: comments })
      }
    },
    // eslint-disable-next-line
    [ACTION_TASK_COMMENT.ADD]: async ({ commit }, payload: { input: ITaskComment, taskId: string }) => {
      return await TaskCommentApi.task(payload.taskId).add(payload.input)
    },
    // eslint-disable-next-line
    [ACTION_TASK_COMMENT.UPDATE]: async ({ commit }, payload: { comment: ITaskComment }) => {
      return await TaskCommentApi.update(payload.comment)
    },
    // eslint-disable-next-line
    [ACTION_TASK_COMMENT.DELETE]: async ({ commit }, payload: { id: string }) => {
      return await TaskCommentApi.delete(payload.id)
    },
  },
}
