import BaseModel from '@/models/BaseModel'
import { IUser, UserModel } from '@/components/user/user-model'
import store from '@/store'
import TaskApi from './task-api'

export enum ETaskState {
  done = 'done',
  undone = 'undone',
}

export interface ITask {
  _id?: string
  workspaceId?: string
  projectId?: string
  creatorId?: string
  title?: string
  description?: string
  state?: ETaskState
  position?: number
  seenUserIds?: string[]
  commentCount?: number

  // eslint-disable-next-line
  customFieldsVal?: Record<string, { value: any }>
}

export class TaskModel extends BaseModel {
  _id?: string
  workspaceId?: string
  projectId?: string

  creatorId?: string
  creator?: IUser

  title?: string
  description?: string
  state?: ETaskState

  seenUserIds?: string[]
  commentCount?: number

  position?: number // Sort ordering

  // eslint-disable-next-line
  customFieldsVal?: Record<string, { value: any }>

  createdAt?: string
  updatedAt?: string

  get loggedUser(): UserModel {
    return store.getters.userInfo || store.getters.guestInfo
  }

  get isSeen() {
    if (!this.loggedUser?._id) {
      return true
    }

    return this.seenUserIds?.includes(this.loggedUser?._id)
  }

  updateSeen() {
    if (!this._id || !this.loggedUser._id || !this.seenUserIds) {
      return
    }

    TaskApi.updateSeen(this._id)
    this.seenUserIds.push(this.loggedUser._id)
  }

  sertialize(): ITask {
    return {
      _id: this._id,
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      creatorId: this.creatorId,
      title: this.title,
      description: this.description,
      state: this.state,
      position: this.position,
      customFieldsVal: this.customFieldsVal,
    }
  }
}
