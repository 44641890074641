import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import quasarUserOptions from './quasar-user-options'
import i18n from './plugins/i18n'
import Quasar from 'quasar/src/vue-plugin.js';
import routerPlugin from './plugins/routerPlugin'
import themePlugin from './plugins/themePlugin'
import metaPlugin from './plugins/metaPlugin'
import filtersPlugin from './plugins/filtersPlugin'

import './register-service-worker'

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(routerPlugin)
  .use(themePlugin)
  .use(metaPlugin)
  .use(filtersPlugin)
  .use(i18n)
  .mount('#app')
