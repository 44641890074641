import gql from 'graphql-tag'

const baseReturn = `
  _id
  workspaceId
  title
  description
  states
`

const WORKFLOW_GQL = {
  LIST_QUERY: gql`
    query workflow_list($workspaceId: String!) {
      workflow_list(workspaceId: $workspaceId) {
        ${baseReturn}
      }
    }
  `,
  SINGLE_QUERY: gql`
    query workflow_single($id: String!) {
      workflow_single(id: $id) {
        ${baseReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation workflow_add($workspaceId: String!, $input: WorkflowInput!) {
      workflow_add(workspaceId: $workspaceId, input: $input) {
        ${baseReturn}
      }
    }
  `,
  UPDATE_MUTATION: gql`
    mutation workflow_update($id: String!, $input: WorkflowInput!) {
      workflow_update(id: $id, input: $input) {
        ${baseReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation workflow_delete($id: String!) {
      workflow_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
}

export default WORKFLOW_GQL
