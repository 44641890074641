import { render } from "./AppSidebarLeftSetting.vue?vue&type=template&id=703ef2be"
import script from "./AppSidebarLeftSetting.vue?vue&type=script&lang=ts"
export * from "./AppSidebarLeftSetting.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QList,QItem,QItemSection,QIcon,QMenu,QSeparator});qInstall(script, 'directives', {Ripple});
