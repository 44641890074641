import { graphqlChangedHandler } from '@/utils/graphql'
import ProjectApi from '@/components/project/project-api'
import { IStoreGetters } from '../../store/types'
import { IProject, ProjectModel } from '@/components/project/project-model'
import { IUserWithPermission } from '@/components/user/user-model'
import { ACTION_PROJECT } from './project-actions'

type TState = {
  items: Record<string, ProjectModel[]>
  item: ProjectModel | null
  selectedIds: Record<string, string[]>
  loading: boolean
}

const initState: TState = {
  items: {},
  item: null,
  selectedIds: {},
  loading: false,
}

export default {
  state: initState,
  getters: {
    projects: (state: TState) => {
      return (workspaceId: string) => {
        return state.items[workspaceId] || []
      }
    },
    projectsColorMap: (state: TState) => {
      return (workspaceId: string) => {
        const colors: Record<string, string> = {}
        const projects = state.items[workspaceId] || [] as ProjectModel[]
        projects.map((project: ProjectModel) => {
          colors[project._id as string] = project.color as string
        })

        return colors
      }
    },
    // eslint-disable-next-line
    ownProjects: (state: TState, getters: IStoreGetters) => {
      if (getters.workspace?._id && getters.isOwnerCurrentWorkspace) {
        return getters.projects(getters.workspace._id)
      }

      return []
    },
    project: (state: TState) => {
      return state.item as ProjectModel
    },
    projectUsers: (state: TState) => {
      return state.item?.members as IUserWithPermission[]
    },
  },
  mutations: {
    // [Projects]
    [ACTION_PROJECT.SET_ITEMS]: (state: TState, payload: { items: ProjectModel[], workspaceId: string }) => {
      state.items[payload.workspaceId] = payload.items
    },
    [ACTION_PROJECT.SET_ITEM]: (state: TState, { item }: { item: ProjectModel }) => {
      state.item = item
    },
    [ACTION_PROJECT.SET_NEW_ITEM]: (state: TState, { item }: { item: ProjectModel }) => {
      if (!item.workspaceId) {
        throw new Error('Unknown project workspaceId')
      }

      state.items[item.workspaceId] = graphqlChangedHandler<ProjectModel>(state.items[item.workspaceId], item)
      if (state.item?._id === item._id) {
        state.item = item
      }
    },
    [ACTION_PROJECT.CLEAR]: (state: TState) => {
      state.item = initState.item
      state.items = initState.items
      state.loading = initState.loading
    },
  },
  actions: {
    [ACTION_PROJECT.LOAD_ITEMS]: async ({ commit }, payload: { workspaceId: string }) => {
      const resp = await ProjectApi.workspace(payload.workspaceId).list()
      if (resp?.length) {
        commit(ACTION_PROJECT.SET_ITEMS, { items: resp, workspaceId: payload.workspaceId })
      }
    },
    [ACTION_PROJECT.ADD]: async ({ commit }, payload: { project: IProject, workspaceId: string }) => {
      const project = await ProjectApi.workspace(payload.workspaceId).add(payload.project)
      if (project) {
        commit(ACTION_PROJECT.SET_NEW_ITEM, { item: project })
      }

      return project
    },
    // eslint-disable-next-line
    [ACTION_PROJECT.LOAD_ITEM]: async ({ commit }, payload: { workspaceId: string, id: string }) => {
      const project = await ProjectApi.single(payload.id)
      if (project) {
        commit(ACTION_PROJECT.SET_ITEM, { item: project })
      }

      return project
    },
    [ACTION_PROJECT.UPDATE]: async ({ commit }, payload: IProject) => {
      const project = await ProjectApi.update(payload)
      if (project) {
        commit(ACTION_PROJECT.SET_NEW_ITEM, { item: project })
      }

      return project
    },
    [ACTION_PROJECT.DELETE]: async ({ commit }, payload: { id: string }) => {
      const project = await ProjectApi.delete(payload.id)
      if (project) {
        commit(ACTION_PROJECT.SET_NEW_ITEM, { item: project })
      }

      return project
    },
  },
}
