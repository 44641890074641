<template>
  <q-btn v-if="workspaces.length && selectedWorkspace" flat no-caps padding="2px">
    <div class="row items-center full-width ellipsis no-wrap" v-if="!miniState">
      <q-avatar size="30px" :style="{ background: stc(selectedWorkspace?.title) }" class="q-mr-xs">
        {{ selectedWorkspace.title[0] }}
      </q-avatar>
      <div class="ellipsis">{{ selectedWorkspace?.title }}</div>
      <q-space />
      <q-icon name="arrow_drop_down" />
    </div>
    <q-avatar v-else square size="40px" color="green">
      {{ selectedWorkspace.title[0] }}
    </q-avatar>
    <q-menu fit anchor="bottom left" self="top left">
      <q-list dense class="q-mt-sm">
        <template v-for="workspace of workspaces" :key="workspace._id">
          <q-item
            v-if="workspace.canRead"
            clickable
            v-ripple
            :active="workspaceId === workspace._id"
            @click="gotoWorkspace(workspace._id)"
          >
            <q-item-section avatar>
              <q-avatar square size="40px" :style="{ background: stc(workspace?.title) }">
                {{ workspace.title[0] }}
              </q-avatar>
            </q-item-section>
            <q-item-section>{{ workspace.title }}</q-item-section>
          </q-item>
        </template>
        <q-separator />

        <q-item clickable v-ripple @click="$goto('workspaces')" class="q-my-sm text-center">
          <q-item-section class="text-grey">See all workspaces</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import stc from 'string-to-color'
import WorkspaceMixin from '../mixins/WorkspaceMixin.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class WorkspaceSwitchBox extends mixins(WorkspaceMixin) {
  stc = stc
  @Prop()
  miniState!: boolean

  get workspaces(): WorkspaceModel[] {
    return this.$store.getters.workspaces || []
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get selectedWorkspace() {
    return this.workspaces.find((workspace) => workspace._id === this.workspaceId)
  }
}
</script>
