import gql from 'graphql-tag'

const baseReturn = `
  _id
  action
  workspaceId
  projectId
  taskId
  text
  project
  task
  user
  createdAt
`

const ACTIVITY_GQL = {
  LIST_QUERY: gql`
    query activity_list($workspaceId: String) {
      activity_list(workspaceId: $workspaceId) {
        ${baseReturn}
      }
    }
  `,
  CHANGE_SUBSCRIBE: gql`
    subscription activity_changed($workspaceId: String) {
      activity_changed(workspaceId: $workspaceId) {
        ${baseReturn}
      }
    }
  `,
}

export default ACTIVITY_GQL
