import BaseModel from '../../models/BaseModel'
import { EUserRole } from '@/components/user/user-model'
import { IWorkspace } from '../workspace/workspace-model'

export interface IInvitationRequest {
  role: EUserRole
  emails: string[]
}

export interface IInvitation {
  _id?: string
  role?: EUserRole
  email?: string
  workspaceId?: string
}

export class InvitationModel extends BaseModel {
  _id?: string
  role?: EUserRole
  email?: string
  workspaceId?: string
  workspace?: IWorkspace
}
