import gql from 'graphql-tag'

const PROJECT_INVITE_EMAILS_GQL = {
  LIST_QUERY: gql`
    query invitation_list($workspaceId: String!) {
      invitation_list(workspaceId: $workspaceId) {
        _id
        role
        email
      }
    }
  `,
  INFO_BY_TOKEN_QUERY: gql`
    query invitation_single_by_token($token: String!) {
      invitation_single_by_token(token: $token) {
        _id
        role
        email
        workspaceId
        workspace {
          _id
          title
        }
      }
    }
  `,
  CONFIRM_JOIN_MUTATION: gql`
    mutation invitation_join_confirmed($token: String!) {
      invitation_join_confirmed(token: $token) {
        _id
        role
        email
        workspace {
          _id
          title
        }
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation invitation_add($workspaceId: String!, $input: InvitationInput!) {
      invitation_add(workspaceId: $workspaceId, input: $input) {
        _id
        role
        email
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation invitation_delete($id: String!) {
      invitation_delete(_id: $id) {
        _id
        role
        email
      }
    }
  `,
}

export default PROJECT_INVITE_EMAILS_GQL
