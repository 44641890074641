
import { Vue, Options } from 'vue-class-component'
import { ACTION_COMMON } from '@/store/actions'
import { ACTION_PROFILE } from '@/components/user/profile-actions'
import { ERROR_CODE } from '@/utils/errorHandler'
import { IUser, UserGuestModel } from '@/components/user/user-model'

@Options({
  components: {},
})
export default class ProfileMixin extends Vue {
  get guestInfo(): UserGuestModel {
    return this.$store.getters.guestInfo || {}
  }

  get guestToken() {
    return this.$guestToken()
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get isValidGuest() {
    return this.guestToken && this.guestInfo?._id
  }

  fetchMe() {
    this.$store.dispatch(ACTION_PROFILE.LOAD)
  }

  async updateProfile(user: IUser) {
    return this.$store.dispatch(ACTION_PROFILE.UPDATE, { user })
  }

  async doLoginSocial(gUser: IUser) {
    if (this.isAuth) {
      return
    }

    this.$store.dispatch(ACTION_PROFILE.LOGIN_FROM_FIREBASE, { gUser })
  }

  async secondLoginWithGuest() {
    return this.$store
      .dispatch(ACTION_PROFILE.LOGIN_GUEST, { token: this.guestToken })
      .then(() => {
        return Promise.resolve(true)
      })
      .catch(error => {
        if (error.errorCode === ERROR_CODE.EXPIRED_GUEST_LINK) {
          this.$goto('guest_page.expired_url')
        }

        return Promise.resolve(false)
      })
  }

  async checkValidGuest() {
    if (!this.isValidGuest) {
      this.$store.dispatch(ACTION_COMMON.SET_NOT_FOUND)
    }
  }
}
