
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class Empty extends Vue {
  isShow = false

  created() {
    setTimeout(() => {
      this.isShow = true
    }, 500)
  }
}
