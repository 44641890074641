import store from '@/store'
import { IGraphqlIgnoreFields } from '@/utils/types'
import { NormApi } from '../../api/norm'
import { TaskModel, ITask } from '@/components/task/task-model'
import { ACTION_TASK } from './task-actions'
import logging from '@/utils/logging'
import TASK_GQL from './task-graphql'

export class TaskApi extends NormApi<TaskModel> {
  workspaceId = ''
  projectId = ''

  constructor() {
    super()
    this.setClassRef(TaskModel)
  }

  workspace(workspaceId: string) {
    this.workspaceId = workspaceId
    return this
  }

  project(projectId: string) {
    this.projectId = projectId
    return this
  }

  listOfGuest = async (): Promise<TaskModel[] | null> => {
    logging.debug('TaskApi.listOfGuest')
    if (this.apolloSubscibe) {
      this.apolloSubscibe.unsubscribe()
    }

    return this.query({
      query: TASK_GQL.LIST_OF_GUEST_QUERY,
      variables: {},
      success: () => {
        this.subscribeToMoreItemOfGuest()
      },
    })
  }

  /**
   * Subcribe task in workspace
   */
  subscribeToMoreItemOfGuest = () => {
    logging.debug('TaskApi.subscribeToMoreItemOfGuest')
    this.subscribe<TaskModel>({
      query: TASK_GQL.CHANGE_OF_GUEST_SUBSCRIBE,
      variables: {},
      success: (data: TaskModel) => {
        store.commit(ACTION_TASK.SET_NEW_ITEM_OF_GUEST, { item: data })
      },
    })
  }

  list = async (): Promise<TaskModel[] | null> => {
    logging.debug('TaskApi.list')
    if (this.apolloSubscibe) {
      this.apolloSubscibe.unsubscribe()
    }

    return this.query({
      query: TASK_GQL.LIST_QUERY,
      variables: {
        projectId: this.projectId,
      },
      success: () => {
        this.subscribeToMore()
      },
    })
  }

  /**
   * Subcribe task in project
   */
  subscribeToMore = () => {
    this.subscribe<TaskModel>({
      query: TASK_GQL.CHANGE_SUBSCRIBE,
      variables: {
        projectId: this.projectId,
      },
      success: (data: TaskModel) => {
        logging.debug('New task changed', data)
        store.commit(ACTION_TASK.SET_NEW_ITEM, { item: data })
      },
    })
  }

  single = async (id: string): Promise<TaskModel | null> => {
    return this.query({
      query: TASK_GQL.SINGLE_QUERY,
      variables: {
        id: id,
      },
    })
  }

  add = async (payload: ITask): Promise<TaskModel | null> => {
    return this.mutation({
      query: TASK_GQL.ADD_MUTATION,
      variables: {
        input: payload,
        projectId: this.projectId,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  update = async (payload: ITask): Promise<TaskModel | null> => {
    const { _id, ...updateData } = this.ignoredFieldsUpdate(payload)
    return this.mutation({
      query: TASK_GQL.UPDATE_MUTATION,
      variables: {
        id: _id,
        input: updateData,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  updateSeen = async (id: string): Promise<TaskModel | null> => {
    return this.mutation({
      query: TASK_GQL.UPDATE_SEEN_MUTATION,
      variables: {
        id,
      },
      success: () => {
        logging.debug('Update seen successed')
      },
    })
  }

  delete = async (id: string): Promise<TaskModel | null> => {
    return this.mutation({
      query: TASK_GQL.DELETE_MUTATION,
      variables: {
        id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Deleted!',
        })
      },
    })
  }

  ignoredFieldsUpdate(payload: ITask & IGraphqlIgnoreFields) {
    /* eslint-disable */
    const {
      changeType,
      workspaceId,
      creatorId,
      ...updateData
    } = payload
    /* eslint-enable */

    return updateData
  }
}

export default new TaskApi()
