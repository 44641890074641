import { IGuestLoginPayload, ILoginPayload, IRegisterPayload } from '@/utils/types'
import { localStore } from '@/utils/localstore'
import { APP_USER_STORE_KEY } from '@/constants/vars'
import { IUser, UserGuestModel, UserModel } from '@/components/user/user-model'
import { ACTION_PROFILE } from './profile-actions'
import { ACTION_WORKSPACE } from '../workspace/workspace-actions'
import { ACTION_PROJECT } from '../project/project-actions'
import { ACTION_TASK } from '../task/task-actions'
import { ACTION_GUEST_URL } from '../guest-url/guest-url-actions'
import ProfileApi from '@/components/user/profile-api'

type TProfileState = {
  user: UserModel | null
  guest: UserGuestModel | null
  guestAuthToken: string
  alreadyGetProfileOnInit: boolean
  loading: boolean
}

export default {
  state: {
    user: null,
    guest: null,
    guestAuthToken: '',
    alreadyGetProfileOnInit: false,
    loading: false,
  },
  getters: {
    isAuth: (state: TProfileState) => {
      return !!state.user
    },
    isLoading: (state: TProfileState) => {
      return state.loading
    },
    userInfo: (state: TProfileState) => {
      return state.user
    },
    guestInfo: (state: TProfileState) => {
      return state.guest
    },
    guestAuthToken: (state: TProfileState) => {
      return state.guestAuthToken
    },
    alreadyGetProfileOnInit: (state: TProfileState) => {
      return state.alreadyGetProfileOnInit
    },
  },
  mutations: {
    [ACTION_PROFILE.SET]: (state: TProfileState, { user }: {
      user: UserModel
    }) => {
      state.user = user
      state.loading = false
      state.alreadyGetProfileOnInit = true
    },
    [ACTION_PROFILE.SET_GUEST]: (state: TProfileState, payload: { user: UserGuestModel }) => {
      state.guest = payload.user
    },
    [ACTION_PROFILE.SET_GUEST_AUTH_TOKEN]: (state: TProfileState, payload: { token: string }) => {
      state.guestAuthToken = payload.token
    },
    [ACTION_PROFILE.SET_DISPLAY_NAME]: (state: TProfileState, { displayName }: {
      displayName: string
    }) => {
      if (!state.user) {
        return
      }

      state.user.displayName = displayName
    },
    [ACTION_PROFILE.CLEAR_PROFILE]: (state: TProfileState) => {
      state.user = null
      state.loading = false
    },
  },
  actions: {
    [ACTION_PROFILE.LOAD]: async ({ commit }: { commit: Function }) => {
      const user = await ProfileApi.me()
      commit(ACTION_PROFILE.SET, { user })
    },
    [ACTION_PROFILE.LOGIN_FROM_FIREBASE]: async ({ commit }: { commit: Function }, { gUser }) => {
      const user = await ProfileApi.loginViaSocial(gUser)
      commit(ACTION_PROFILE.SET, { user })
    },
    [ACTION_PROFILE.LOGIN]: async ({ commit }: { commit: Function }, payload: ILoginPayload) => {
      const user = await ProfileApi.login(payload)
      if (user) {
        commit(ACTION_PROFILE.SET, { user })
      }

      return user
    },
    [ACTION_PROFILE.LOGIN_GUEST]: async ({ commit }: { commit: Function }, payload: IGuestLoginPayload) => {
      const resp = await ProfileApi.loginGuest(payload)
      if (resp?.user) {
        commit(ACTION_PROFILE.SET_GUEST, { user: resp.user })
      }

      if (resp?.token) {
        commit(ACTION_PROFILE.SET_GUEST_AUTH_TOKEN, { token: resp.token })
      }
    },
    [ACTION_PROFILE.REGISTER]: async ({ commit }: { commit: Function }, payload: IRegisterPayload) => {
      const user = await ProfileApi.register(payload)
      if (user) {
        commit(ACTION_PROFILE.SET, { user })
      }

      return user
    },
    [ACTION_PROFILE.UPDATE]: async ({ commit }: { commit: Function }, payload: { user: IUser }) => {
      const user = await ProfileApi.updateProfile(payload.user)
      if (user) {
        commit(ACTION_PROFILE.SET, { user })
      }
    },
    [ACTION_PROFILE.LOGOUT]: async ({ commit }: { commit: Function }) => {
      commit(ACTION_PROFILE.CLEAR_PROFILE)
      commit(ACTION_WORKSPACE.CLEAR)
      commit(ACTION_PROJECT.CLEAR)
      commit(ACTION_TASK.CLEAR)
      commit(ACTION_GUEST_URL.CLEAR)
      localStore.removeItem(APP_USER_STORE_KEY.AUTH_TOKEN)
    },
  },
}
