
import { Prop } from 'vue-property-decorator'
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import stc from 'string-to-color'
import WorkspaceMixin from '../mixins/WorkspaceMixin.vue'

@Options({
  components: {},
  directives: { maska },
})
export default class WorkspaceSwitchBox extends mixins(WorkspaceMixin) {
  stc = stc
  @Prop()
  miniState!: boolean

  get workspaces(): WorkspaceModel[] {
    return this.$store.getters.workspaces || []
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get selectedWorkspace() {
    return this.workspaces.find((workspace) => workspace._id === this.workspaceId)
  }
}
