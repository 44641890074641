import { graphqlChangedHandler } from '@/utils/graphql'
import WorkflowApi from '@/components/workflow/workflow-api'
import { IWorkflow, WorkflowModel } from '@/components/workflow/workflow-model'
import { ACTION_WORKFLOW } from './workflow-actions'

type TState = {
  items: Record<string, WorkflowModel[]>
  item: WorkflowModel | null
  loading: boolean
}

const initState: TState = {
  items: { },
  item: null,
  loading: false,
}

export default {
  state: initState,
  getters: {
    workflows: (state: TState) => {
      return (workspaceId: string) => {
        return state.items[workspaceId] || []
      }
    },
    workflow: (state: TState) => {
      return state.item
    },
  },
  mutations: {
    // [Workflows]
    [ACTION_WORKFLOW.SET_ITEMS]: (state: TState, payload: { items: WorkflowModel[], workspaceId: string }) => {
      state.items[payload.workspaceId] = payload.items
    },
    [ACTION_WORKFLOW.SET_ITEM]: (state: TState, payload: { item: WorkflowModel }) => {
      state.item = payload.item
    },
    [ACTION_WORKFLOW.SET_NEW_ITEM]: (state: TState, { item }: { item: WorkflowModel }) => {
      if (!item.workspaceId) {
        throw new Error('Unknown workflow workspaceId')
      }

      state.items[item.workspaceId] = graphqlChangedHandler<WorkflowModel>(state.items[item.workspaceId], item)
    },
    [ACTION_WORKFLOW.CLEAR]: (state: TState) => {
      state.items = initState.items
      state.loading = initState.loading
    },
  },
  actions: {
    [ACTION_WORKFLOW.LOAD_ITEMS]: async ({ commit }, payload: { workspaceId: string }) => {
      const resp = await WorkflowApi.workspace(payload.workspaceId).list()
      if (resp?.length) {
        commit(ACTION_WORKFLOW.SET_ITEMS, { items: resp, workspaceId: payload.workspaceId })
      }
    },
    [ACTION_WORKFLOW.ADD]: async ({ commit }, payload: { workflow: IWorkflow, workspaceId: string }) => {
      const item = await WorkflowApi.workspace(payload.workspaceId).add(payload.workflow)
      if (item) {
        commit(ACTION_WORKFLOW.SET_NEW_ITEM, { item })
      }

      return item
    },
    // eslint-disable-next-line
    [ACTION_WORKFLOW.LOAD_ITEM]: async ({ commit }, payload: { workspaceId: string, id: string, guestToken?: string }) => {
      return await WorkflowApi.single(payload.id, payload.guestToken)
    },
    [ACTION_WORKFLOW.UPDATE]: async ({ commit }, payload: IWorkflow) => {
      const item = await WorkflowApi.update(payload)
      if (item) {
        commit(ACTION_WORKFLOW.SET_NEW_ITEM, { item })
      }

      return item
    },
    [ACTION_WORKFLOW.DELETE]: async ({ commit }, payload: { id: string }) => {
      const item = await WorkflowApi.delete(payload.id)
      if (item) {
        commit(ACTION_WORKFLOW.SET_NEW_ITEM, { item })
      }

      return item
    },
  },
}
