import WORKFLOW_GQL from '@/components/workflow/workflow-graphql'
import { IWorkflow, WorkflowModel } from '@/components/workflow/workflow-model'
import { IGraphqlIgnoreFields } from '@/utils/types'
import { NormApi } from '../../api/norm'

export class WorkflowApi extends NormApi<WorkflowModel> {
  workspaceId = ''

  constructor() {
    super()
    this.setClassRef(WorkflowModel)
  }

  workspace(workspaceId: string) {
    this.workspaceId = workspaceId
    return this
  }

  list = async (): Promise<WorkflowModel[] | null> => {
    return this.query({
      query: WORKFLOW_GQL.LIST_QUERY,
      variables: {
        workspaceId: this.workspaceId,
      },
    })
  }

  single = async (id: string, guestToken?: string): Promise<WorkflowModel | null> => {
    return this.query({
      query: WORKFLOW_GQL.SINGLE_QUERY,
      variables: {
        guestToken,
        id: id,
      },
    })
  }

  add = async (payload: IWorkflow): Promise<WorkflowModel | null> => {
    return this.mutation({
      query: WORKFLOW_GQL.ADD_MUTATION,
      variables: {
        input: payload,
        workspaceId: this.workspaceId,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  update = async (payload: IWorkflow & IGraphqlIgnoreFields): Promise<WorkflowModel | null> => {
    // eslint-disable-next-line
    const { _id, changeType, ...updateData } = payload
    return this.mutation({
      query: WORKFLOW_GQL.UPDATE_MUTATION,
      variables: {
        id: _id,
        input: updateData,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  delete = async (id: string): Promise<WorkflowModel | null> => {
    return this.mutation({
      query: WORKFLOW_GQL.DELETE_MUTATION,
      variables: {
        id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Deleted!',
        })
      },
    })
  }
}

const workflowApi = new WorkflowApi()
export default workflowApi
