import gql from 'graphql-tag'

const baseReturn = `
  _id
  workspaceId
  title
  description
  position
  fields
`

const FORM_GQL = {
  LIST_QUERY: gql`
    query form_list($workspaceId: String!) {
      form_list(workspaceId: $workspaceId) {
        ${baseReturn}
      }
    }
  `,
  SINGLE_QUERY: gql`
    query form_single($id: String!) {
      form_single(id: $id) {
        ${baseReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation form_add($workspaceId: String!, $input: FormInput!) {
      form_add(workspaceId: $workspaceId, input: $input) {
        ${baseReturn}
      }
    }
  `,
  UPDATE_MUTATION: gql`
    mutation form_update($id: String!, $input: FormInput!) {
      form_update(id: $id, input: $input) {
        ${baseReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation form_delete($id: String!) {
      form_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
}

export default FORM_GQL
