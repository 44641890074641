import { render } from "./WorkspaceSwitchBox.vue?vue&type=template&id=8013339e"
import script from "./WorkspaceSwitchBox.vue?vue&type=script&lang=ts"
export * from "./WorkspaceSwitchBox.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QAvatar,QSpace,QIcon,QMenu,QList,QItem,QItemSection,QSeparator});qInstall(script, 'directives', {Ripple});
