
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop } from 'vue-property-decorator'
import { IProject, ProjectModel } from '@/components/project/project-model'

import ProjectFormModal from '@/components/project/ProjectFormModal.vue'
import ProjectMixin from '../mixins/ProjectMixin.vue'

@Options({
  components: {
    ProjectFormModal,
  },
  directives: { maska },
})
export default class ProjectsLeftSidebar extends mixins(ProjectMixin) {
  @Prop()
  miniState!: boolean

  searchQuery = ''
  modalVisibleProjectForm = false
  selectedProject: ProjectModel | null = null

  get projectId() {
    return this.$route.params.projectId as string
  }

  set projectId(projectId: string) {
    this.$goto('workspace_board.projects', { projectId })
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get projects(): ProjectModel[] {
    return this.$store.getters.projects(this.workspaceId) || []
  }

  get shouldShow() {
    return this.$route.name !== 'workspace_board.dashboard'
  }

  onCloseModalProjectForm() {
    this.modalVisibleProjectForm = false
    this.selectedProject = null
  }

  onEditProject(project: ProjectModel) {
    this.selectedProject = project
    this.modalVisibleProjectForm = true
  }

  onProjectClick(project: IProject) {
    if (!project._id) {
      return
    }

    this.projectId = project._id
  }
}
