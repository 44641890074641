import { createStore } from 'vuex'
import { ACTION_COMMON } from './actions'
import ProfileModule from '../components/user/profile-store'
import Workspace from '../components/workspace/workspace-store'
import Workflow from '../components/workflow/workflow-store'
import Form from '../components/form/form-store'
import Project from '../components/project/project-store'
import Task from '../components/task/task-store'
import TaskComment from '../components/comment/task-comment-store'
import GuestUrl from '../components/guest-url/guest-url-store'
import Invitation from '../components/invitation/invitation-store'
import Activity from '../components/activity/activity-store'

export interface TAppState {
  isNotFound: boolean
  notFoundItem: string
}

export default createStore({
  strict: true,
  state: {
    isNotFound: false,
    notFoundItem: '',
  },
  mutations: {
    [ACTION_COMMON.SET_NOT_FOUND]: (state: TAppState, payload?: { item: string }) => {
      state.isNotFound = true
      state.notFoundItem = payload?.item || ''
    },
    [ACTION_COMMON.RESET_NOT_FOUND]: (state: TAppState) => {
      state.isNotFound = false
      state.notFoundItem = ''
    },
  },
  actions: {
    [ACTION_COMMON.SET_NOT_FOUND]: ({ commit }: {
      commit: Function
    }, payload: {
      item: string
    }) => {
      commit(ACTION_COMMON.SET_NOT_FOUND, payload)
    },
    [ACTION_COMMON.RESET_NOT_FOUND]: ({ commit }: {
      commit: Function
    }) => {
      commit(ACTION_COMMON.RESET_NOT_FOUND)
    },
  },
  modules: {
    profile: ProfileModule,
    workspace: Workspace,
    workflow: Workflow,
    form: Form,
    project: Project,
    task: Task,
    taskComment: TaskComment,
    invitation: Invitation,
    activity: Activity,
    guestUrl: GuestUrl,
  },
})
