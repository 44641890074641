import gql from 'graphql-tag'

const baseReturn = `
  _id
  workspaceId
  formId
  workflowId
  title
  description
  permissions
  form {
    _id
    title
    fields
  }
  color
  members {
    _id
    role
    displayName
    username
    photoURL
  }
  isAllowGuestPost
`

const workflowReturn = `
  workflow {
    _id
    title
    states
  }
`

const PROJECT_GQL = {
  LIST_QUERY: gql`
    query project_list($workspaceId: String!) {
      project_list(workspaceId: $workspaceId) {
        ${baseReturn}
      }
    }
  `,
  SINGLE_QUERY: gql`
    query project_single($id: String!) {
      project_single(id: $id) {
        ${baseReturn}
        ${workflowReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation project_add($workspaceId: String!, $input: ProjectInput!) {
      project_add(workspaceId: $workspaceId, input: $input) {
        ${baseReturn}
        ${workflowReturn}
      }
    }
  `,
  UPDATE_MUTATION: gql`
    mutation project_update($id: String!, $input: ProjectInput!) {
      project_update(id: $id, input: $input) {
        ${baseReturn}
        ${workflowReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation project_delete($id: String!) {
      project_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
  CHANGE_SUBSCRIBE: gql`
    subscription project_changed {
      project_changed {
        ${baseReturn}
        changeType
      }
    }
  `,
}

export default PROJECT_GQL
