
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import QForm from 'quasar/src/components/form/QForm.js';
import { maska } from 'maska'
import { COLORS_LIST } from '@/constants/vars'
import { IProject, ProjectModel } from '@/components/project/project-model'
import { FormModel } from '../form/form-model'

import ProjectMixin from '@/components/project/mixins/ProjectMixin.vue'
import ColorPickerDropdown from '@/components/color/ui/ColorPickerDropdown.vue'
import logging from '@/utils/logging'
import FormMixin from '../form/mixins/FormMixin.vue'
import CLoading from '../common/ui/CLoading.vue'
import WorkflowMixin from '../workflow/mixins/WorkflowMixin.vue'
import { WorkflowModel } from '../workflow/workflow-model'

@Options({
  components: { ColorPickerDropdown, CLoading },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class ProjectFormModal extends mixins(ProjectMixin, FormMixin, WorkflowMixin) {
  @Prop({ default: {} })
  projectData!: ProjectModel

  @Prop()
  modalVisible!: boolean

  isComponentReady = false

  get loading() {
    return !this.isComponentReady || this.$store.state.project.loading
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get forms(): FormModel[] {
    return this.$store.getters.forms(this.workspaceId) || []
  }

  get workflows(): WorkflowModel[] {
    return this.$store.getters.workflows(this.workspaceId)
  }

  requiredRule = {
    message: 'This field is required',
  }

  form: IProject = {
    formId: '',
    workflowId: '',
    title: '',
    description: '',
    color: COLORS_LIST[0],
  }

  rules = {
    title: [this.requiredRule],
  }

  get isNew() {
    return !this.projectData?._id
  }

  get modalTitle() {
    return this.isNew ? 'Create new Project' : 'Edit project'
  }

  get isMissingForms() {
    return this.isComponentReady && !this.forms.length
  }

  onSubmitProject() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSaveProject()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSaveProject() {
    if (this.isNew) {
      return this.addProject(this.form)
    } else {
      return this.updateProject(this.form)
    }
  }

  async getFormsList() {
    if (this.forms.length) {
      return
    }

    return this.getForms()
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    this.form = { ...this.form, ...this.projectData?.sertialize() }
    await Promise.all([this.getFormsList(), this.getWorkflows()])

    this.isComponentReady = true
  }
}
