import gql from 'graphql-tag'

const baseReturn = `
  _id
  taskId
  message
  creatorId
  attachments
  creator {
    _id
    displayName
    photoURL
  }
  updatedAt
  createdAt
`

const TASK_COMMENT_GQL = {
  LIST_QUERY: gql`
    query task_comment_list($taskId: String!) {
      task_comment_list(taskId: $taskId) {
        ${baseReturn}
      }
    }
  `,
  SINGLE_QUERY: gql`
    query task_comment_single($id: String!) {
      task_comment_single(id: $id) {
        ${baseReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation task_comment_add($taskId: String!, $input: TaskCommentInput!) {
      task_comment_add(taskId: $taskId, input: $input) {
        ${baseReturn}
      }
    }
  `,
  UPDATE_MUTATION: gql`
    mutation task_comment_update($id: String!, $input: TaskCommentInput!) {
      task_comment_update(id: $id, input: $input) {
        ${baseReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation task_comment_delete($id: String!) {
      task_comment_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
  CHANGE_SUBSCRIBE: gql`
    subscription task_comment_changed($taskId: String!) {
      task_comment_changed(taskId: $taskId) {
        ${baseReturn}
        changeType
      }
    }
  `,
}

export default TASK_COMMENT_GQL
