
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import SelectThemeModal from '../common/theme/SelectThemeModal.vue'

@Options({
  components: {
    SelectThemeModal,
  },
})
export default class AppSidebarLeftSetting extends Vue {
  @Prop()
  miniState = false

  themeSelectVisible = false

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.params?.id || this.$route.meta?.menuKey
  }
}
