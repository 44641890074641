const prefix = 'sango-tasks-'
export const localStore = {
    makeKey: (key: string) => {
        return `${prefix}${key}`
    },
    getItem: (key: string) => {
        return localStorage.getItem(localStore.makeKey(key))
    },
    setItem: (key: string, value: string) => {
        return localStorage.setItem(localStore.makeKey(key), value)
    },
    remove: (key: string) => {
        return localStorage.removeItem(localStore.makeKey(key))
    },
    removeItem: (key: string) => {
        return localStorage.removeItem(localStore.makeKey(key))
    },
    getUser: () => {
        try {
            const userRaw = localStorage.getItem(localStore.makeKey('user')) as string
            return JSON.parse(userRaw)
        } catch {
            return null
        }
    },
}
