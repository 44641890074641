
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { localStore } from '@/utils/localstore'
import debounce from 'quasar/src/utils/debounce.js';
import AppLogo from '@/components/common/AppLogo.vue'
import ProjectsLeftSidebar from '@/components/project/ui/ProjectsLeftSidebar.vue'
import AppSidebarLeftSetting from './AppSidebarLeftSetting.vue'

@Options({
  components: {
    AppLogo,
    AppSidebarLeftSetting,
    ProjectsLeftSidebar,
  },
  emits: ['update:modelValue'],
})
export default class AppSidebarLeft extends Vue {
  @Prop({ default: true })
  modelValue!: boolean

  get leftDrawerOpen() {
    return this.modelValue
  }

  set leftDrawerOpen(value: boolean) {
    this.$emit('update:modelValue', value)
  }

  miniState = false

  collapseKey = 'leftmenu-collapse'

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.params?.id || this.$route.meta?.menuKey
  }

  @Watch('miniState')
  onCollapseChanged() {
    if (this.miniState) {
      localStore.setItem(this.collapseKey, `${this.miniState}`)
    } else {
      localStore.removeItem(this.collapseKey)
    }

    // Remove scroll left on mini state. Quasar has a strange thing here
    debounce(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const leftSidebarEl = this.$refs.leftSidebarRef as any
      if (leftSidebarEl) {
        leftSidebarEl.$el.classList.remove('q-drawer--mini-animate')
      }
    }, 150).call(this)
  }

  created() {
    this.miniState = !!localStore.getItem(this.collapseKey)
  }
}
