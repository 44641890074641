import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import { IGraphqlIgnoreFields } from './types'

export const graphqlChangedHandler = <T>(currentList: (T & IGraphqlIgnoreFields)[], newData: T & IGraphqlIgnoreFields) => {
  const list = cloneDeep(currentList) || []
  if (newData.changeType === 'added') {
    list.push(newData)
    return uniqBy(list, '_id')
  }

  const index = list.findIndex((item) => item._id === newData._id)
  if (newData.changeType === 'removed') {
    list.splice(index, 1)
  } else if (newData.changeType === 'modified') {
    list[index] = newData
  }

  return uniqBy(list, '_id')
}
