export const ACTION_FORM = {
  LOAD_ITEMS: 'FORM/LOAD_ITEMS',
  LOAD_ITEM: 'FORM/LOAD_ITEM',
  ADD: 'FORM/ADD',
  UPDATE: 'FORM/UPDATE',
  DELETE: 'FORM/DELETE',
  SET_ITEMS: 'FORM/SET_ITEMS',
  SET_NEW_ITEM: 'FORM/SET_NEW_ITEM',
  SET_ITEM: 'FORM/SET_ITEM',
  CLEAR: 'FORM/ACTION_PROJECT',
}
