import BaseModel from '@/models/BaseModel'

export enum EUserRole {
  member = 'member',
  owner = 'owner',
}

export interface IUser {
  _id?: string
  displayName?: string
  username?: string
  email?: string
  photoURL?: string
  uid?: string
  password?: string
}

export type IUserGuest = IUser & {
  projectId?: string
}

export type IUserWithPermission = IUser & {
  role?: string
}

export class UserModel extends BaseModel {
  _id?: string
  displayName?: string
  email?: string
  photoURL?: string
  uid?: string

  sertialize(): IUser {
    return {
      _id: this._id,
      displayName: this.displayName,
      email: this.email,
      photoURL: this.photoURL,
      uid: this.uid,
    }
  }
}

export class UserGuestModel extends UserModel {
  projectId?: string

  sertialize(): IUserGuest {
    return {
      ...super.sertialize(),
      projectId: this.projectId,
    }
  }
}

export class UserWithPermissionModel extends UserModel {
  role?: EUserRole

  sertialize(): IUserWithPermission {
    return {
      ...super.sertialize(),
      role: this.role,
    }
  }
}
