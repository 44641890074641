import store from '@/store'
import TASK_COMMENT_GQL from './task-comment-graphql'
import { NormApi } from '../../api/norm'
import { IGraphqlIgnoreFields } from '@/utils/types'
import { TaskCommentModel, ITaskComment } from '@/components/comment/task-comment-model'
import { ACTION_TASK_COMMENT } from './task-comment-actions'

export class TaskCommentApi extends NormApi<TaskCommentModel> {
  taskId = ''

  constructor() {
    super()
    this.setClassRef(TaskCommentModel)
  }

  task(taskId: string) {
    this.taskId = taskId
    return this
  }

  list = async (): Promise<TaskCommentModel[] | null> => {
    if (this.apolloSubscibe) {
      this.apolloSubscibe.unsubscribe()
    }

    return this.query({
      query: TASK_COMMENT_GQL.LIST_QUERY,
      variables: {
        taskId: this.taskId,
      },
      success: () => {
        this.subscribeToMore()
      },
    })
  }

  subscribeToMore = () => {
    this.subscribe<TaskCommentModel>({
      query: TASK_COMMENT_GQL.CHANGE_SUBSCRIBE,
      variables: {
        taskId: this.taskId,
      },
      success: (data: TaskCommentModel) => {
        store.commit(ACTION_TASK_COMMENT.SET_NEW_ITEM, { item: data })
      },
    })
  }

  single = async (id: string): Promise<TaskCommentModel | null> => {
    return this.query({
      query: TASK_COMMENT_GQL.SINGLE_QUERY,
      variables: {
        id: id,
      },
    })
  }

  add = async (payload: ITaskComment): Promise<TaskCommentModel | null> => {
    return this.mutation({
      query: TASK_COMMENT_GQL.ADD_MUTATION,
      variables: {
        input: payload,
        taskId: this.taskId,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  update = async (payload: ITaskComment & IGraphqlIgnoreFields): Promise<TaskCommentModel | null> => {
    // eslint-disable-next-line
    const { _id, changeType, taskId, creatorId, ...updateData } = payload
    return this.mutation({
      query: TASK_COMMENT_GQL.UPDATE_MUTATION,
      variables: {
        id: _id,
        input: updateData,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  delete = async (id: string): Promise<TaskCommentModel | null> => {
    return this.mutation({
      query: TASK_COMMENT_GQL.DELETE_MUTATION,
      variables: {
        id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Deleted!',
        })
      },
    })
  }
}

export default new TaskCommentApi()
