import gql from 'graphql-tag'

const baseReturn = `
  _id
  workspaceId
  projectId
  project {
    _id
    title
  }
  displayName
  token
  active
  settings
  updatedAt
  createdAt
`

const GUEST_URL_GQL = {
  LIST_QUERY: gql`
    query guest_url_list($workspaceId: String!) {
      guest_url_list(workspaceId: $workspaceId) {
        ${baseReturn}
      }
    }
  `,
  SINGLE_QUERY: gql`
    query guest_url_single($id: String!) {
      guest_url_single(id: $id) {
        ${baseReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation guest_url_add($input: GuestUrlInput!) {
      guest_url_add(input: $input) {
        ${baseReturn}
      }
    }
  `,
  UPDATE_MUTATION: gql`
    mutation guest_url_update($id: String!, $input: GuestUrlInput!) {
      guest_url_update(id: $id, input: $input) {
        ${baseReturn}
      }
    }
  `,
  REFRESH_MUTATION: gql`
    mutation guest_url_update_refresh($id: String!) {
      guest_url_update_refresh(id: $id) {
        ${baseReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation guest_url_delete($id: String!) {
      guest_url_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
}

export default GUEST_URL_GQL
