// Profile
export const ACTION_PROFILE = {
  SET_DISPLAY_NAME: 'PROFILE/SET_DISPLAY_NAME',
  SET: 'PROFILE/SAVE',
  SET_GUEST: 'PROFILE/SAVE_GUEST',
  SET_GUEST_AUTH_TOKEN: 'PROFILE/SET_GUEST_AUTH_TOKEN',
  LOAD: 'PROFILE/LOAD',
  UPDATE: 'PROFILE/UPDATE',
  LOGOUT: 'PROFILE/LOGOUT',
  LOGIN: 'PROFILE/LOGIN',
  LOGIN_GUEST: 'PROFILE/LOGIN_GUEST',
  REGISTER: 'PROFILE/REGISTER',
  LOGIN_FROM_FIREBASE: 'PROFILE/LOGIN_FROM_FIREBASE',
  CLEAR_PROFILE: 'PROFILE/CLEAR_PROFILE',
}
