import { IUser, IUserGuest } from '@/components/user/user-model'
export enum EChangeType {
  added = 'added',
  modified = 'modified',
  removed = 'removed',
}

export interface IGraphqlIgnoreFields {
  _id?: string
  color?: string
  changeType?: EChangeType
}

export interface IGraphqlError {
  message: string
  extensions: {
    code: string
  }
}

export interface ILoginPayload {
  email: string
  password: string
}

export interface ISlackAuthResponse {
  access_token: string
  scope: string
  team_name: string
  team_id: string
  incoming_webhook: string
}

export interface IGuestLoginPayload {
  token: string
}

export interface ILoginResponse {
  token: string
  user: IUser
}

export interface ILoginGuestResponse {
  token: string
  user: IUserGuest
  projectId: string
}

export interface IRegisterPayload {
  email: string
  displayName: string
  password: string
}

// File upload
export interface IFileItem {
  uid: string
  name?: string
  status?: string
  response?: string
  url?: string
  type?: string
  size: number
  originFileObj: unknown
}

export interface IFileUploadResult {
  url: string
  filename: string
  encoding: string
  mimetype: string
  size: number
}

export type CustomFieldValue = string | number | string[] | IFileUploadResult[]
