<template>
  <q-drawer
    breakpoint="1024"
    ref="leftSidebarRef"
    side="left"
    show-if-above
    v-model="leftDrawerOpen"
    :mini="miniState"
    class="left-sidebar"
    :class="{ 'left-sidebar--is-collapsed': miniState }"
  >
    <q-scroll-area class="fit">
      <q-list dense class="row column q-py-none absolute absolute-bottom-left absolute-top-right">
        <template v-if="workspaceId">
          <ProjectsLeftSidebar :miniState="miniState" />

          <div class="flex-grow"></div>

          <AppSidebarLeftSetting :miniState="miniState" />
        </template>
      </q-list>
    </q-scroll-area>

    <div class="mini-drawer-toggle absolute">
      <q-btn
        round
        unelevated
        color="accent"
        padding="4px"
        size="sm"
        @click="miniState = !miniState"
        :icon="miniState ? 'arrow_forward_ios' : 'arrow_back_ios_new'"
      />
    </div>
  </q-drawer>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { localStore } from '@/utils/localstore'
import { debounce } from 'quasar'
import AppLogo from '@/components/common/AppLogo.vue'
import ProjectsLeftSidebar from '@/components/project/ui/ProjectsLeftSidebar.vue'
import AppSidebarLeftSetting from './AppSidebarLeftSetting.vue'

@Options({
  components: {
    AppLogo,
    AppSidebarLeftSetting,
    ProjectsLeftSidebar,
  },
  emits: ['update:modelValue'],
})
export default class AppSidebarLeft extends Vue {
  @Prop({ default: true })
  modelValue!: boolean

  get leftDrawerOpen() {
    return this.modelValue
  }

  set leftDrawerOpen(value: boolean) {
    this.$emit('update:modelValue', value)
  }

  miniState = false

  collapseKey = 'leftmenu-collapse'

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.params?.id || this.$route.meta?.menuKey
  }

  @Watch('miniState')
  onCollapseChanged() {
    if (this.miniState) {
      localStore.setItem(this.collapseKey, `${this.miniState}`)
    } else {
      localStore.removeItem(this.collapseKey)
    }

    // Remove scroll left on mini state. Quasar has a strange thing here
    debounce(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const leftSidebarEl = this.$refs.leftSidebarRef as any
      if (leftSidebarEl) {
        leftSidebarEl.$el.classList.remove('q-drawer--mini-animate')
      }
    }, 150).call(this)
  }

  created() {
    this.miniState = !!localStore.getItem(this.collapseKey)
  }
}
</script>
<style lang="scss">
.q-drawer-container {
  .mini-drawer-toggle {
    height: 100vh;
    opacity: 0;
    right: -12px;
    top: 0;
    transition: opacity 0.2s ease-in-out;
    .q-btn {
      position: relative;
      top: 14px;
      right: 0;
    }
    &:hover {
      opacity: 1;
    }
  }
  .left-sidebar {
    padding: 0 10px;
    border-right: 1px solid #d5d9de;
    &--is-collapsed {
      padding: 0;
    }
    .q-item {
      border-radius: 5px;
    }
  }
  .q-scrollarea__content {
    max-width: 100%;
  }
}
</style>
