import useMeta from 'quasar/src/composables/use-meta.js';
import { App } from 'vue'

interface IMeta {
  title: string[]
  description?: string
}

export default {
  install: (app: App) => {
    const appName = process.env.VUE_APP_NAME || ''

    const getTitle = (title: string[]) => {
      const titleParts = title.concat([appName])
      return titleParts.join(' | ')
    }

    const setMeta = (configs: IMeta) => {
      useMeta({
        ...configs,
        title: getTitle(configs.title),
      })
    }

    const getFavicon = (hasNew: boolean) => {
      // TODO: need a favicon contain notification dot
      return hasNew ? 'img/icons/favicon-32x32.png' : 'img/icons/favicon-32x32.png'
    }

    // Change favicon as has new event
    const setNew = (hasNew: boolean) => {
      useMeta({
        link: {
          favicon: { rel: 'icon', href: getFavicon(hasNew) },
        },
      })
    }

    const funcs = {
      setMeta,
      setNew,
    }

    app.config.globalProperties.$meta = funcs
    app.provide('meta', funcs)
  },
}
