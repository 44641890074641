
import BaseModel from '@/models/BaseModel'

export enum EActivityAction {
  createdTask = 'created_task',
  updatedTask = 'updated_task',
  deletedTask = 'deleted_task',
  addedComment = 'added_comment'
}

export interface IActivity {
  _id?: string
  action?: EActivityAction
  userId?: string
  targetUserIds?: string[]
  workspaceId?: string
  projectId?: string
  taskId?: string
  text?: string
}

export class ActivityModel extends BaseModel {
  _id?: string
  action?: EActivityAction
  userId?: string
  targetUserIds?: string[]
  workspaceId?: string
  projectId?: string
  taskId?: string
  text?: string
  createdAt?: string

  sertialize(): IActivity {
    return {
      _id: this._id,
      action: this.action,
      userId: this.userId,
      targetUserIds: this.targetUserIds || [],
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      taskId: this.taskId,
      text: this.text,
    }
  }
}
