
import { Vue, Options } from 'vue-class-component'
import { ACTION_ACTIVITY } from '../activity-actions'

@Options({
  components: {},
})
export default class ActivityMixin extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId
  }

  async getActivityList() {
    return this.$store.dispatch(ACTION_ACTIVITY.LOAD_ITEMS, { workspaceId: this.workspaceId })
  }

  async startWatchingActivity() {
    this.$store.dispatch(ACTION_ACTIVITY.INIT_WATCHING, { workspaceId: this.workspaceId })
  }
}
