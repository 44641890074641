import PROFILE_GQL from '@/components/user/user-graphql'
import { localStore } from '@/utils/localstore'
import {
  IGraphqlError,
  IGuestLoginPayload,
  ILoginGuestResponse,
  ILoginPayload,
  ILoginResponse,
  IRegisterPayload,
} from '@/utils/types'
import { NormApi } from '../../api/norm'
import { APP_USER_STORE_KEY } from '@/constants/vars'
import { IUser, UserGuestModel, UserModel } from '@/components/user/user-model'

export class ProfileApi extends NormApi<UserModel> {
  me = async (): Promise<UserModel | null> => {
    const token = localStore.getItem(APP_USER_STORE_KEY.AUTH_TOKEN)
    if (!token) {
      return Promise.resolve(null)
    }

    const user = await this.query<IUser | null>({
      query: PROFILE_GQL.ME_QUERY,
      error: (error: IGraphqlError) => {
        if (error.message === '401') {
          localStore.removeItem(APP_USER_STORE_KEY.AUTH_TOKEN)
        }
      },
    })

    if (!user) {
      return Promise.resolve(user)
    }

    return Promise.resolve(new UserModel(user))
  }

  login = async (payload: ILoginPayload): Promise<UserModel | null> => {
    const resp = await this.mutation<ILoginResponse | null>({
      query: PROFILE_GQL.LOGIN_MUTATION,
      variables: {
        input: payload,
      },
      success: (data: ILoginResponse) => {
        this.handleAuthSuccess(data)
      },
    })

    if (!resp) {
      return Promise.resolve(resp)
    }

    return Promise.resolve(new UserModel(resp.user))
  }

  loginGuest = async (payload: IGuestLoginPayload): Promise<{ token: string, user: UserGuestModel } | null> => {
    let error
    const resp = await this.mutation<ILoginGuestResponse | null>({
      query: PROFILE_GQL.LOGIN_GUEST_MUTATION,
      variables: {
        input: payload,
      },
      error: (err: IGraphqlError) => {
        error = err
      },
    })

    if (error) {
      return Promise.reject(error)
    }

    if (!resp) {
      return Promise.resolve(resp)
    }

    return Promise.resolve({
      user: new UserGuestModel({ ...resp.user, projectId: resp.projectId }),
      token: resp.token,
    })
  }

  register = async (payload: IRegisterPayload): Promise<UserModel | null> => {
    const resp = await this.mutation<ILoginResponse | null>({
      query: PROFILE_GQL.REGISTER_MUTATION,
      variables: {
        input: payload,
      },
      success: (data: ILoginResponse) => {
        this.handleAuthSuccess(data)
      },
    })

    if (!resp) {
      return Promise.resolve(resp)
    }

    return Promise.resolve(new UserModel(resp.user))
  }

  loginViaSocial = async (gUser: IUser): Promise<UserModel | null> => {
    const resp = await this.mutation<ILoginResponse | null>({
      query: PROFILE_GQL.SOCIAL_LOGIN_MUTATION,
      variables: {
        input: gUser,
      },
      success: (data: ILoginResponse) => {
        this.handleAuthSuccess(data)
      },
    })

    if (!resp) {
      return Promise.resolve(resp)
    }

    return Promise.resolve(new UserModel(resp.user))
  }

  updateProfile = async (user: IUser): Promise<UserModel | null> => {
    const resp = await this.mutation<IUser | null>({
      query: PROFILE_GQL.UPDATE_PROFILE_MUTATION,
      variables: {
        input: user,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved',
        })
      },
    })

    if (!resp) {
      return Promise.resolve(resp)
    }

    return Promise.resolve(new UserModel(resp))
  }

  handleAuthSuccess(data: ILoginResponse) {
    const user = data.user
    const token = data.token
    if (user && token) {
      localStore.setItem(APP_USER_STORE_KEY.AUTH_TOKEN, token)
    }
  }
}

const profileApi = new ProfileApi()
export default profileApi
