<template>
  <q-dialog v-model="visible">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title> Change theme </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <div class="q-pa-md q-gutter-sm text-center">
          <q-btn
            v-for="theme in allThemes"
            size="lg"
            :key="theme.name"
            :color="selectedTheme === theme.name ? 'primary' : 'grey'"
            :label="theme.title"
            @click="selectedTheme = theme.name"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class SelectThemeModal extends Vue {
  @Prop()
  modalVisible!: boolean

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get allThemes() {
    return this.$theme.themes
  }

  get selectedTheme() {
    return this.$theme.selected()
  }

  set selectedTheme(val: string) {
    this.$theme.setTheme(val)
  }
}
</script>
