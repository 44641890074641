
import { Vue, Options } from 'vue-class-component'
import { ACTION_COMMON } from '@/store/actions'
import { localStore } from '@/utils/localstore'
import { APP_USER_STORE_KEY } from '@/constants/vars'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { ACTION_WORKSPACE } from '../workspace-actions'

@Options({
  components: {},
})
export default class WorkspaceMixin extends Vue {
  get workspaces(): WorkspaceModel[] {
    return this.$store.getters.workspaces || []
  }

  get workspaceId() {
    return this.$route.params.workspaceId as string
  }

  get isWorkspaceSetting() {
    return this.$route.name === 'workspaces'
  }

  get cachedWorkspaceId() {
    return this.workspaceId || localStore.getItem(APP_USER_STORE_KEY.SELECTED_WORKSPACE) || this.workspaces[0]?._id
  }

  async getWorkspace(workspaceId: string) {
    const workspace = await this.$store.dispatch(ACTION_WORKSPACE.LOAD_ITEM, {
      id: workspaceId,
    })

    if (!workspace) {
      this.$store.dispatch(ACTION_COMMON.SET_NOT_FOUND)
    }
  }

  async getWorkspaces() {
    return this.$store.dispatch(ACTION_WORKSPACE.LOAD_ITEMS)
  }

  async addWorkspace(workspace: WorkspaceModel) {
    return this.$store.dispatch(ACTION_WORKSPACE.ADD, workspace)
  }

  async updateWorkspace(workspace: WorkspaceModel) {
    return this.$store.dispatch(ACTION_WORKSPACE.UPDATE, workspace)
  }

  async removeWorkspace(workspace: WorkspaceModel) {
    return this.$store.dispatch(ACTION_WORKSPACE.DELETE, { id: workspace._id })
  }

  gotoCreateWorkspace() {
    return this.$router.push({
      name: 'workspaces',
    })
  }

  gotoWorkspace(workspaceId: string) {
    if (!workspaceId || workspaceId === this.workspaceId) {
      return
    }

    localStore.setItem(APP_USER_STORE_KEY.SELECTED_WORKSPACE, workspaceId)
    return this.$goto('workspace_board', {
      workspaceId,
    })
  }

  gotoDefaultWorkspace() {
    const workspace = this.workspaces.find((workspace) => workspace._id === this.cachedWorkspaceId)

    // Maybe localstore workspaceId not contains on workspaces list
    // In that case we should switch to first workspace on list
    const gotoWorkspaceId = workspace?._id || this.workspaces[0]?._id
    if (gotoWorkspaceId) {
      return this.gotoWorkspace(gotoWorkspaceId)
    } else {
      this.gotoCreateWorkspace()
    }
  }

  checkAndSwitchWorkspace() {
    // Check and switch to default workspace
    if (!this.isWorkspaceSetting) {
      // If has workspaces list and is not guest user
      // Goto workspace list to create new workspace first
      if (!this.workspaces.length) {
        this.gotoCreateWorkspace()
      } else {
        this.gotoDefaultWorkspace()
      }
    }
  }
}
