import store from '@/store'
import BaseModel from '../../models/BaseModel'
import { IUser, UserModel } from '@/components/user/user-model'

export interface ITaskComment {
  _id?: string
  taskId?: string
  creatorId?: string
  message?: string
}

export class TaskCommentModel extends BaseModel {
  _id?: string
  taskId?: string
  creatorId?: string
  creator?: IUser
  message?: string

  createdAt?: string
  updatedAt?: string

  get loggedUser(): UserModel {
    return store.getters.userInfo || store.getters.guestInfo
  }

  get canRead() {
    return true
  }

  get canWrite() {
    return this.creatorId === this.loggedUser?._id
  }

  sertialize(): ITaskComment {
    return {
      _id: this._id,
      taskId: this.taskId,
      creatorId: this.creatorId,
      message: this.message,
    }
  }
}
