import { render } from "./ActivityButton.vue?vue&type=template&id=77dca770"
import script from "./ActivityButton.vue?vue&type=script&lang=ts"
export * from "./ActivityButton.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QBadge});
