export const ACTION_TASK_COMMENT = {
  LOAD_ITEMS: 'TASK_COMMENT/LOAD_ITEMS',
  LOAD_ITEM: 'TASK_COMMENT/LOAD_ITEM',
  ADD: 'TASK_COMMENT/ADD',
  UPDATE: 'TASK_COMMENT/UPDATE',
  DELETE: 'TASK_COMMENT/DELETE',
  SET_ITEMS: 'TASK_COMMENT/SET_ITEMS',
  SET_NEW_ITEM: 'TASK_COMMENT/SET_NEW_ITEM',
  SET_ITEM: 'TASK_COMMENT/SET_ITEM',
  CLEAR: 'TASK_COMMENT/CLEAR',
}
