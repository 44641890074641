import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NotFound from '@/components/common/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/workspace/WorkspaceBoard.vue'),
    meta: {
      restricted: true,
      hasLeftSidebar: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/noauth/Login.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/noauth/Register.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/g/:guestToken',
    name: 'guest_page',
    component: () => import(/* webpackChunkName: "guest_page" */ '../views/guest-mode/GuestPage.vue'),
    meta: {
      restricted: false,
      isGuestPage: true,
    },
    children: [
      {
        path: 'tasks/:taskId?',
        name: 'guest_page.tasks',
        component: () => import(/* webpackChunkName: "guest_page" */ '../views/guest-mode/GuestPage.vue'),
        meta: {
          isGuestPage: true,
        },
      },
    ],
  },
  {
    path: '/expired-url',
    name: 'guest_page.expired_url',
    component: () => import(/* webpackChunkName: "guest_page_expired_url" */ '../views/guest-mode/ExpiredGuestUrl.vue'),
  },
  {
    path: '/workspaces',
    name: 'workspaces',
    component: () => import(/* webpackChunkName: "workspaces" */ '../views/workspace/WorkspaceList.vue'),
    meta: {
      restricted: true,
      menuKey: 'workspaces',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/w/:workspaceId',
    name: 'workspace_board',
    component: () => import(/* webpackChunkName: "workspace_board" */ '../views/workspace/WorkspaceBoard.vue'),
    meta: {
      restricted: true,
      menuKey: 'workspace_board',
      hasLeftSidebar: true,
    },
    redirect: {
      name: 'workspace_board.dashboard',
    },
    children: [
      {
        path: 'dashboard',
        name: 'workspace_board.dashboard',
        component: () => import(/* webpackChunkName: "workspace_board.dashboard" */ '../views/workspace/Dashboard.vue'),
        meta: {
          restricted: true,
          menuKey: 'dashboard',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'p/:projectId?',
        name: 'workspace_board.projects',
        component: () => import(/* webpackChunkName: "tasks" */ '../views/workspace/Tasks.vue'),
        meta: {
          restricted: true,
          menuKey: 'projects',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'p/:projectId/tasks/:taskId?',
        name: 'workspace_board.tasks',
        component: () => import(/* webpackChunkName: "tasks" */ '../views/workspace/Tasks.vue'),
        meta: {
          restricted: true,
          menuKey: 'tasks',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'members',
        name: 'workspace_board.members',
        component: () => import(/* webpackChunkName: "members" */ '../views/workspace-settings/MembersSetting.vue'),
        meta: {
          restricted: true,
          menuKey: 'members',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'forms',
        name: 'workspace_board.forms',
        component: () => import(/* webpackChunkName: "forms" */ '../views/workspace-settings/form/FormsSetting.vue'),
        meta: {
          restricted: true,
          menuKey: 'forms',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'forms/fields/:formId?',
        name: 'workspace_board.form_fields',
        component: () =>
          import(/* webpackChunkName: "form_fields" */ '../views/workspace-settings/form/FormFieldsEditor.vue'),
        meta: {
          restricted: true,
          hasLeftSidebar: true,
        },
      },
      {
        path: 'guest-urls',
        name: 'workspace_board.guest_urls',
        component: () => import(/* webpackChunkName: "guest_urls" */ '../views/workspace-settings/GuestUrls.vue'),
        meta: {
          restricted: true,
          menuKey: 'guest_urls',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'intergration/:service?',
        name: 'workspace_board.intergration',
        component: () => import(/* webpackChunkName: "intergration" */ '../views/workspace-settings/Intergration.vue'),
        meta: {
          restricted: true,
          menuKey: 'intergration',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'workflows',
        name: 'workspace_board.workflows',
        component: () =>
          import(/* webpackChunkName: "workflows" */ '../views/workspace-settings/workflow/WorkflowsSetting.vue'),
        meta: {
          restricted: true,
          menuKey: 'workflows',
          hasLeftSidebar: true,
        },
      },
      {
        path: 'workflows/:workflowId',
        name: 'workspace_board.workflow_states',
        component: () =>
          import(
            /* webpackChunkName: "wworkflows_states_setting" */ '../views/workspace-settings/workflow/WorkflowStatesSetting.vue'
          ),
        meta: {
          restricted: true,
          menuKey: 'workflow_states',
          hasLeftSidebar: true,
        },
      },
    ],
  },
  {
    path: '/join/:token',
    name: 'join_workspace',
    component: () => import(/* webpackChunkName: "join_workspace" */ '../views/join/JoinWorkspace.vue'),
    meta: {
      restricted: false,
      menuKey: 'workspace',
      hasLeftSidebar: false,
    },
  },
  {
    path: '/users/:id',
    name: 'user_profile_view',
    component: () => import(/* webpackChunkName: "user_profile_view" */ '../views/user/ProfileSetting.vue'),
    meta: {
      restricted: true,
      menuKey: 'users',
    },
  },
  {
    path: '/profile/edit/:tab?',
    name: 'profile_setting',
    component: () => import(/* webpackChunkName: "profile_setting" */ '../views/user/ProfileSetting.vue'),
    meta: {
      restricted: true,
      menuKey: 'profile_setting',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/redirect/slack',
    name: 'redirect_slack',
    component: () => import(/* webpackChunkName: "redirect_slack" */ '../views/redirect/SlackRedirect.vue'),
    meta: {
      restricted: true,
      hasLeftSidebar: true,
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/help/Help.vue'),
    meta: {
      restricted: false,
      menuKey: 'help',
      hasLeftSidebar: true,
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
    meta: {
      header: {
        logo: false,
        homeButtton: true,
      },
      restricted: false,
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
