
import { Options, Vue } from 'vue-class-component'

import AppHasSidebarLeft from '@/components/layout/AppHasSidebarLeft.vue'
import AppGuestMode from '@/components/layout/AppGuestMode.vue'
import NotFound from '@/components/common/NotFound.vue'
import AppFooter from '@/components/layout/AppFooter.vue'
import logging from './utils/logging'

@Options({
  components: {
    AppHasSidebarLeft,
    AppGuestMode,
    AppFooter,
    NotFound,
  },
})
export default class App extends Vue {
  get alreadyGetProfileOnInit() {
    return this.$store.getters.alreadyGetProfileOnInit
  }

  get isNotFound() {
    return this.$store.state.isNotFound
  }

  get isCredentialRoute() {
    return this.$route.name === 'login' || this.$route.name === 'register'
  }

  get hasLeftSidebar() {
    return this.$route.meta?.hasLeftSidebar
  }

  get isGuestPage() {
    return this.$route.meta.isGuestPage
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get isRestrictedPage() {
    return this.$route.meta.restricted
  }

  beforeMount() {
    logging.debugRender(App.name)
  }
}
