import INVITATION_GQL from '@/components/invitation/invitation-graphql'
import { InvitationModel, IInvitationRequest } from '@/components/invitation/invitation-model'
import { NormApi } from '../../api/norm'

export class InvitationApi extends NormApi<InvitationModel> {
  workspaceId = ''

  constructor() {
    super()
    this.setClassRef(InvitationModel)
  }

  workspace(workspaceId: string) {
    this.workspaceId = workspaceId
    return this
  }

  list = async (): Promise<InvitationModel[] | null> => {
    return this.query({
      query: INVITATION_GQL.LIST_QUERY,
      variables: {
        workspaceId: this.workspaceId,
      },
    })
  }

  infoByToken = async (token: string): Promise<InvitationModel | null> => {
    return this.query({
      query: INVITATION_GQL.INFO_BY_TOKEN_QUERY,
      variables: {
        token,
      },
    })
  }

  joinByToken = async (token: string): Promise<InvitationModel | null> => {
    return this.mutation({
      query: INVITATION_GQL.CONFIRM_JOIN_MUTATION,
      variables: {
        token,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Success!',
        })
      },
    })
  }

  add = async (payload: IInvitationRequest): Promise<InvitationModel[] | null> => {
    return this.mutation({
      query: INVITATION_GQL.ADD_MUTATION,
      variables: {
        input: payload,
        workspaceId: this.workspaceId,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Success!',
        })
      },
    })
  }

  delete = async (id: string): Promise<InvitationModel | null> => {
    return this.mutation({
      query: INVITATION_GQL.DELETE_MUTATION,
      variables: {
        id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Deleted!',
        })
      },
    })
  }
}

export default new InvitationApi()
