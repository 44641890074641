<template>
  <router-link to="/">
    <q-img src="/img/sango-tech-logo.png" width="50px" />
  </router-link>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class AppLogo extends Vue {
  gotoHome() {
    this.$router.push({ name: 'home' })
  }
}
</script>
