import { ActivityModel } from '@/components/activity/activity-model'
import { ACTION_ACTIVITY } from './activity-actions'
import ActivityApi from './activity-api'

type TState = {
  items: ActivityModel[]
  lastActivity: ActivityModel | null
  loading: boolean
}

const initState: TState = {
  items: [],
  lastActivity: null,
  loading: false,
}

export default {
  state: initState,
  getters: {
    activities: (state: TState) => {
      return state.items
    },
    lastActivity: (state: TState) => {
      return state.lastActivity
    },
  },
  mutations: {
    // [Activitys]
    [ACTION_ACTIVITY.SET_ITEMS]: (state: TState, { items }: { items: ActivityModel[] }) => {
      state.loading = false
      state.items = items
    },
    [ACTION_ACTIVITY.SET_LAST_ACTIVITY]: (state: TState, { item }: { item: ActivityModel }) => {
      state.lastActivity = item
    },
    [ACTION_ACTIVITY.SET_LOADING]: (state: TState) => {
      state.loading = true
    },
  },
  actions: {
    [ACTION_ACTIVITY.LOAD_ITEMS]: async ({ commit }, payload: { workspaceId: string }) => {
      commit(ACTION_ACTIVITY.SET_LOADING)
      const resp = await ActivityApi.list(payload.workspaceId)
      commit(ACTION_ACTIVITY.SET_ITEMS, { items: resp || [] })
    },
    // eslint-disable-next-line
    [ACTION_ACTIVITY.INIT_WATCHING]: async ({ commit }, payload: { workspaceId: string }) => {
      ActivityApi.subscribeNewActivity(payload.workspaceId)
    },
  },
}
