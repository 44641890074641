import { graphqlChangedHandler } from '@/utils/graphql'
import { InvitationModel, IInvitationRequest } from '@/components/invitation/invitation-model'
import { ACTION_INVITATION } from './invitation-actions'
import InvitationApi from '@/components/invitation/invitation-api'

type TState = {
  items: InvitationModel[]
  item: InvitationModel | null
  loading: boolean
}

export default {
  state: {
    items: [],
    item: { },
    loading: false,
  },
  getters: {
    invitations: (state: TState) => {
      return state.items
    },
  },
  mutations: {
    // [Invitations]
    [ACTION_INVITATION.SET_ITEMS]: (state: TState, { items }: { items: InvitationModel[] }) => {
      state.items = items
    },
    [ACTION_INVITATION.SET_NEW_ITEM]: (state: TState, { item }: { item: InvitationModel }) => {
      state.items = graphqlChangedHandler<InvitationModel>(state.items, item)
    },
    // [Set selected customField]
    [ACTION_INVITATION.SET_ITEM]: (state: TState, { item }: { item: InvitationModel }) => {
      state.item = item
    },
  },
  actions: {
    [ACTION_INVITATION.LOAD_ITEMS]: async ({ commit }, payload: { workspaceId: string }) => {
      const resp = await InvitationApi.workspace(payload.workspaceId).list() || []
      commit(ACTION_INVITATION.SET_ITEMS, { items: resp })
    },
    // eslint-disable-next-line
    [ACTION_INVITATION.ADD]: async ({ commit }, payload: { input: IInvitationRequest, workspaceId: string }) => {
      return InvitationApi.workspace(payload.workspaceId).add(payload.input)
    },
    // eslint-disable-next-line
    [ACTION_INVITATION.DELETE]: async ({ commit }, payload: { id: string }) => {
      return InvitationApi.delete(payload.id)
    },
  },
}
