import { graphqlChangedHandler } from '@/utils/graphql'
import { IStoreGetters } from '../../store/types'
import { IWorkspace, IWorkspacePermission } from '@/components/workspace/workspace-model'
import WorkspaceApi from '@/components/workspace/workspace-api'
import { EUserRole } from '@/components/user/user-model'
import { EChangeType } from '@/utils/types'
import { ACTION_WORKSPACE } from './workspace-actions'

type TState = {
  items: IWorkspace[]
  item: IWorkspace | null
  loading: boolean
}

const initState: TState = {
  items: [],
  item: {},
  loading: false,
}

export default {
  state: initState,
  getters: {
    workspaces: (state: TState) => {
      return state.items
    },
    workspace: (state: TState) => {
      return state.item
    },
    // eslint-disable-next-line
    isOwnerOfWorkspace(state: TState, getters: IStoreGetters) {
      return (workspace: IWorkspace) => {
        const userInfo = getters.userInfo
        if (!userInfo) {
          return false
        }

        const permissions = workspace.permissions || []
        return (
          !permissions.length ||
          permissions.some(
            (per: IWorkspacePermission) => per.role === EUserRole.owner && per.userId === userInfo._id
          )
        )
      }
    },
    // eslint-disable-next-line
    isOwnerCurrentWorkspace(state: TState, getters: IStoreGetters) {
      return getters.isOwnerOfWorkspace(getters.workspace)
    },
  },
  mutations: {
    // [Workspaces]
    [ACTION_WORKSPACE.SET_ITEMS]: (state: TState, { items }: { items: IWorkspace[] }) => {
      state.items = items
    },
    [ACTION_WORKSPACE.SET_ITEM]: (state: TState, { item }: { item: IWorkspace }) => {
      state.item = item
    },
    [ACTION_WORKSPACE.SET_NEW_ITEM]: (state: TState, { item }: { item: IWorkspace }) => {
      state.items = graphqlChangedHandler<IWorkspace>(state.items, item)
      if (state.item?._id === item._id) {
        state.item = item
      }
    },
    [ACTION_WORKSPACE.CLEAR]: (state: TState) => {
      state.item = initState.item
      state.items = initState.items
      state.loading = initState.loading
    },
  },
  actions: {
    [ACTION_WORKSPACE.LOAD_ITEMS]: async ({ commit }) => {
      const resp = await WorkspaceApi.list()
      if (resp?.length) {
        commit(ACTION_WORKSPACE.SET_ITEMS, { items: resp })
      }
    },
    [ACTION_WORKSPACE.LOAD_ITEM]: async ({ commit, state }, payload: { id: string }) => {
      let workspace = state.items.find((item: IWorkspace) => item._id === payload.id)
      if (!workspace) {
        workspace = await WorkspaceApi.single(payload.id)
        if (workspace) {
          workspace.changeType = EChangeType.added
          commit(ACTION_WORKSPACE.SET_NEW_ITEM, { item: workspace })
        }
      }

      commit(ACTION_WORKSPACE.SET_ITEM, { item: workspace })

      return workspace
    },
    [ACTION_WORKSPACE.ADD]: async ({ commit }, payload: IWorkspace) => {
      const item = await WorkspaceApi.add(payload)
      if (item) {
        commit(ACTION_WORKSPACE.SET_NEW_ITEM, { item })
      }

      return item
    },
    [ACTION_WORKSPACE.UPDATE]: async ({ commit }, payload: IWorkspace) => {
      const item = await WorkspaceApi.update(payload)
      if (item) {
        commit(ACTION_WORKSPACE.SET_NEW_ITEM, { item })
      }

      return item
    },
    [ACTION_WORKSPACE.DELETE]: async ({ commit }, payload: { id: string }) => {
      const item = await WorkspaceApi.delete(payload.id)
      if (item) {
        commit(ACTION_WORKSPACE.SET_NEW_ITEM, { item })
      }
    },
  },
}
