import PROJECT_GQL from '@/components/project/project-graphql'
import { ProjectModel, IProject } from '@/components/project/project-model'
import { IGraphqlIgnoreFields } from '@/utils/types'
import { NormApi } from '../../api/norm'

export class ProjectApi extends NormApi<ProjectModel> {
  constructor() {
    super()
    this.setClassRef(ProjectModel)
  }

  workspaceId = ''

  workspace(workspaceId: string) {
    this.workspaceId = workspaceId
    return this
  }

  list = async (): Promise<ProjectModel[] | null> => {
    if (this.apolloSubscibe) {
      this.apolloSubscibe.unsubscribe()
    }

    return this.query({
      query: PROJECT_GQL.LIST_QUERY,
      variables: {
        workspaceId: this.workspaceId,
      },
    })
  }

  single = async (id: string): Promise<ProjectModel | null> => {
    return this.query({
      query: PROJECT_GQL.SINGLE_QUERY,
      variables: {
        id: id,
      },
    })
  }

  add = async (payload: IProject): Promise<ProjectModel | null> => {
    return this.mutation({
      query: PROJECT_GQL.ADD_MUTATION,
      variables: {
        input: payload,
        workspaceId: this.workspaceId,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  update = async (payload: IProject & IGraphqlIgnoreFields): Promise<ProjectModel | null> => {
    // eslint-disable-next-line
    const { _id, changeType, workspaceId, ...updateData } = payload
    return this.mutation({
      query: PROJECT_GQL.UPDATE_MUTATION,
      variables: {
        id: _id,
        input: updateData,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  delete = async (id: string): Promise<ProjectModel | null> => {
    return this.mutation({
      query: PROJECT_GQL.DELETE_MUTATION,
      variables: {
        id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Deleted!',
        })
      },
    })
  }
}

const projectApi = new ProjectApi()
export default projectApi
