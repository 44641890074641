<template>
  <template v-if="shouldShow">
    <div class="q-mt-sm text-grey-8 text-weight-medium q-ml-md q-mt-md" :class="{ hidden: miniState }">PROJECTS</div>
    <q-list dense class="q-mt-sm max-w-full-pt">
      <q-item
        clickable
        v-ripple
        v-for="project of projects"
        :active="projectId === project._id"
        :key="project._id"
        class="c-animate"
      >
        <q-item-section avatar @click="onProjectClick(project)">
          <q-icon name="view_quilt" :style="{ color: project.color }" />
        </q-item-section>

        <q-item-section class="ellipsis" @click="onProjectClick(project)">
          {{ project.title }}
        </q-item-section>

        <q-item-section side v-if="project.canWrite" class="c-animate--hover-display">
          <q-btn flat icon="more_vert" padding="0" round size="md" text-color="grey-4">
            <q-menu anchor="bottom left" self="center left">
              <q-list dense>
                <q-item clickable v-close-popup @click="onEditProject(project)">
                  <q-item-section> Edit project </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-item-section>
      </q-item>

      <!-- new project button -->
      <q-item
        v-if="workspace?.canWrite"
        clickable
        v-ripple
        @click="modalVisibleProjectForm = true"
        class="q-mt-sm text-grey-8"
      >
        <q-item-section avatar>
          <q-icon name="add" />
        </q-item-section>
        <q-item-section>New project</q-item-section>
      </q-item>
    </q-list>

    <ProjectFormModal
      v-if="modalVisibleProjectForm"
      :modalVisible="modalVisibleProjectForm"
      :projectData="selectedProject"
      @update:closeModal="onCloseModalProjectForm"
    />
  </template>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop } from 'vue-property-decorator'
import { IProject, ProjectModel } from '@/components/project/project-model'

import ProjectFormModal from '@/components/project/ProjectFormModal.vue'
import ProjectMixin from '../mixins/ProjectMixin.vue'

@Options({
  components: {
    ProjectFormModal,
  },
  directives: { maska },
})
export default class ProjectsLeftSidebar extends mixins(ProjectMixin) {
  @Prop()
  miniState!: boolean

  searchQuery = ''
  modalVisibleProjectForm = false
  selectedProject: ProjectModel | null = null

  get projectId() {
    return this.$route.params.projectId as string
  }

  set projectId(projectId: string) {
    this.$goto('workspace_board.projects', { projectId })
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get projects(): ProjectModel[] {
    return this.$store.getters.projects(this.workspaceId) || []
  }

  get shouldShow() {
    return this.$route.name !== 'workspace_board.dashboard'
  }

  onCloseModalProjectForm() {
    this.modalVisibleProjectForm = false
    this.selectedProject = null
  }

  onEditProject(project: ProjectModel) {
    this.selectedProject = project
    this.modalVisibleProjectForm = true
  }

  onProjectClick(project: IProject) {
    if (!project._id) {
      return
    }

    this.projectId = project._id
  }
}
</script>
