
import { Vue, Options } from 'vue-class-component'
import { ACTION_COMMON } from '@/store/actions'
import { IWorkflow } from '@/components/workflow/workflow-model'
import { ACTION_WORKFLOW } from '../workflow-actions'

@Options({
  components: {},
})
export default class WorkflowMixin extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId
  }

  async getWorkflow(workflowId: string) {
    const workflow = await this.$store.dispatch(ACTION_WORKFLOW.LOAD_ITEM, {
      id: workflowId,
    })

    if (!workflow) {
      this.$store.dispatch(ACTION_COMMON.SET_NOT_FOUND)
    }

    return workflow
  }

  async getWorkflows() {
    if (!this.workspaceId) {
      return
    }

    return this.$store.dispatch(ACTION_WORKFLOW.LOAD_ITEMS, { workspaceId: this.workspaceId })
  }

  async addWorkflow(workflow: IWorkflow) {
    return this.$store.dispatch(ACTION_WORKFLOW.ADD, { workflow, workspaceId: this.workspaceId })
  }

  async updateWorkflow(workflow: IWorkflow) {
    return this.$store.dispatch(ACTION_WORKFLOW.UPDATE, workflow)
  }

  async removeWorkflow(workflow: IWorkflow) {
    return this.$store.dispatch(ACTION_WORKFLOW.DELETE, { id: workflow._id })
  }
}
