export const ACTION_WORKSPACE = {
  LOAD_ITEMS: 'WORKSPACE/LOAD_ITEMS',
  LOAD_ITEM: 'WORKSPACE/LOAD_ITEM',
  ADD: 'WORKSPACE/ADD',
  UPDATE: 'WORKSPACE/UPDATE',
  DELETE: 'WORKSPACE/DELETE',
  SET_ITEMS: 'WORKSPACE/SET_ITEMS',
  SET_NEW_ITEM: 'WORKSPACE/SET_NEW_ITEM',
  SET_ITEM: 'WORKSPACE/SET_ITEM',
  CLEAR: 'WORKSPACE/ACTION_PROJECT',
}
