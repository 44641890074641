<template>
  <div
    v-if="isShow"
    class="empty-container full-width full-height row column flex-center text-grey q-gutter-sm q-pa-lg"
  >
    <div>
      <q-icon size="3em" name="golf_course" />
    </div>
    <span> No data found </span>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class Empty extends Vue {
  isShow = false

  created() {
    setTimeout(() => {
      this.isShow = true
    }, 500)
  }
}
</script>
<style lang="scss" scoped>
.empty-container {
  max-height: calc(100vh - 200px);
}
</style>
