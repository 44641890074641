import { render } from "./Empty.vue?vue&type=template&id=7a42f45a&scoped=true"
import script from "./Empty.vue?vue&type=script&lang=ts"
export * from "./Empty.vue?vue&type=script&lang=ts"

import "./Empty.vue?vue&type=style&index=0&id=7a42f45a&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7a42f45a"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon});
