import cloneDeep from 'lodash/cloneDeep'
import GuestUrlApi from '@/components/guest-url/guest-url-api'
import { GuestUrlModel, IGuestUrl } from '@/components/guest-url/guest-url-model'
import { graphqlChangedHandler } from '@/utils/graphql'
import { ACTION_GUEST_URL } from './guest-url-actions'

type TState = {
  items: Record<string, GuestUrlModel[]>
  loading: boolean
}

const initState: TState = {
  items: {},
  loading: false,
}

export default {
  state: initState,
  getters: {
    guestUrls: (state: TState) => {
      return (workspaceId: string) => {
        return state.items[workspaceId] || []
      }
    },
  },
  mutations: {
    // [List]
    [ACTION_GUEST_URL.SET_ITEMS]: (state: TState, payload: { items: GuestUrlModel[], workspaceId: string }) => {
      state.items[payload.workspaceId] = payload.items
    },
    // [New item]
    [ACTION_GUEST_URL.SET_NEW_ITEM]: (state: TState, { item }: { item: GuestUrlModel }) => {
      if (!item.workspaceId) {
        throw new Error('Unknown workspaceId')
      }

      state.items[item.workspaceId] = graphqlChangedHandler<GuestUrlModel>(
        state.items[item.workspaceId],
        cloneDeep(item)
      )
    },
    [ACTION_GUEST_URL.CLEAR]: (state: TState) => {
      state.items = initState.items
      state.loading = initState.loading
    },
  },
  actions: {
    [ACTION_GUEST_URL.LOAD_ITEMS]: async ({ commit }, payload: { workspaceId: string }) => {
      const resp = await GuestUrlApi.workspace(payload.workspaceId).list()
      if (resp?.length) {
        commit(ACTION_GUEST_URL.SET_ITEMS, { items: resp, workspaceId: payload.workspaceId })
      }
    },
    // eslint-disable-next-line
    [ACTION_GUEST_URL.ADD]: async ({ commit }, payload: { guestUrl: IGuestUrl }) => {
      const item = await GuestUrlApi.add(payload.guestUrl)
      if (item) {
        commit(ACTION_GUEST_URL.SET_NEW_ITEM, { item })
      }

      return item
    },

    // eslint-disable-next-line
    [ACTION_GUEST_URL.UPDATE]: async ({ commit }, payload: IGuestUrl) => {
      const item = await GuestUrlApi.update(payload)
      if (item) {
        commit(ACTION_GUEST_URL.SET_NEW_ITEM, { item })
      }

      return item
    },
    // eslint-disable-next-line
    [ACTION_GUEST_URL.REFRESH_TOKEN]: async ({ commit }, payload: IGuestUrl) => {
      const item = await GuestUrlApi.refresh(payload)
      if (item) {
        commit(ACTION_GUEST_URL.SET_NEW_ITEM, { item })
      }

      return item
    },
    // eslint-disable-next-line
    [ACTION_GUEST_URL.DELETE]: async ({ commit }, payload: { id: string }) => {
      const item = await GuestUrlApi.delete(payload.id)
      if (item) {
        commit(ACTION_GUEST_URL.SET_NEW_ITEM, { item })
      }

      return item
    },
    [ACTION_GUEST_URL.SHOW_EXPIRED]: ({ commit }) => {
      commit(ACTION_GUEST_URL.SHOW_EXPIRED)
    },
  },
}
