
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ProjectModel } from '@/components/project/project-model'
import { ActivityModel } from '@/components/activity/activity-model'

import ActivityMixin from './mixins/ActivityMixin.vue'
import CLoading from '../common/ui/CLoading.vue'
import Empty from '../common/ui/Empty.vue'
import ActivityItem from './ui/ActivityItem.vue'

@Options({
  components: { CLoading, Empty, ActivityItem },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class ActivityViewModal extends mixins(ActivityMixin) {
  @Prop({ default: {} })
  projectData!: ProjectModel

  @Prop()
  modalVisible!: boolean

  get loading() {
    return this.$store.state.activity.loading
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get activities(): ActivityModel[] {
    return this.$store.getters.activities
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    this.getActivityList()
  }
}
