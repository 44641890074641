<template>
  <q-btn
    :class="{ 'q-mr-md': hasNewActivity }"
    padding="5px 10px"
    @click="setLastSeenActivityAt"
    icon="insights"
    flat
    no-caps
    @click.prevent="modalActivityVisible = true"
  >
    Activity
    <q-badge v-if="hasNewActivity" color="red" rounded floating />
  </q-btn>

  <ActivityViewModal
    v-if="modalActivityVisible"
    :modalVisible="modalActivityVisible"
    @update:closeModal="modalActivityVisible = false"
  />
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { maska } from 'maska'
import { localStore } from '@/utils/localstore'
import { APP_USER_STORE_KEY } from '@/constants/vars'

import ActivityMixin from '../mixins/ActivityMixin.vue'
import ActivityViewModal from '@/components/activity/ActivityViewModal.vue'
import dayjs from 'dayjs'
import logging from '@/utils/logging'

@Options({
  components: { ActivityViewModal },
  directives: { maska },
})
export default class ActivityButton extends mixins(ActivityMixin) {
  modalActivityVisible = false
  componentKey = 1

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get lastActivityAt() {
    return this.$store.getters.lastActivity?.createdAt
  }

  get storeKey() {
    return `${APP_USER_STORE_KEY.LAST_SEEN_ACTIVITY_AT}-${this.workspaceId}`
  }

  get lastSeenActivityAt() {
    if (!this.componentKey) {
      return ''
    }

    return localStore.getItem(this.storeKey) || ''
  }

  set lastSeenActivityAt(val: string) {
    localStore.setItem(this.storeKey, val)
    this.componentKey++
  }

  get hasNewActivity() {
    return (
      this.lastActivityAt && (!this.lastSeenActivityAt || dayjs(this.lastActivityAt) > dayjs(this.lastSeenActivityAt))
    )
  }

  setLastSeenActivityAt() {
    this.lastSeenActivityAt = dayjs().toISOString()
  }

  created() {
    logging.debugRender(ActivityButton.name)
    // [ActivityMixin]
    this.startWatchingActivity()
  }
}
</script>
