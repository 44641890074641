<template>
  <q-list dense class="q-pb-sm">
    <template v-if="isAuth">
      <div class="q-mt-md q-mb-sm text-grey-8 text-weight-medium q-ml-md" :class="{ hidden: miniState }">SETTINGS</div>
      <q-item
        v-if="workspace?.canWrite"
        clickable
        v-ripple
        @click="$goto('workspace_board.guest_urls')"
        :active="activeRouteKey === 'guest_urls'"
      >
        <q-item-section avatar>
          <q-icon size="xs" name="share" />
        </q-item-section>

        <q-item-section> Guest URLs </q-item-section>
      </q-item>

      <q-item clickable v-ripple>
        <q-item-section avatar>
          <q-icon size="xs" name="more_horiz" />
        </q-item-section>

        <q-item-section> More </q-item-section>

        <q-menu anchor="center middle" self="center right" class="whitespace-nowrap q-py-sm">
          <q-list dense>
            <q-item clickable v-ripple @click="$goto('workspace_board.members')">
              <q-item-section avatar>
                <q-icon size="xs" name="group" />
              </q-item-section>

              <q-item-section> Members </q-item-section>
            </q-item>

            <q-item clickable v-ripple @click="$goto('workspaces')">
              <q-item-section avatar>
                <q-icon size="xs" name="workspaces" />
              </q-item-section>

              <q-item-section> Workspace manage </q-item-section>
            </q-item>

            <q-item clickable v-ripple @click="$goto('workspace_board.workflows')">
              <q-item-section avatar>
                <q-icon size="xs" name="low_priority" />
              </q-item-section>

              <q-item-section> Workflows setting </q-item-section>
            </q-item>

            <template v-if="workspace?.canWrite">
              <q-item clickable v-ripple @click="$goto('workspace_board.forms')">
                <q-item-section avatar>
                  <q-icon size="xs" name="view_stream" />
                </q-item-section>

                <q-item-section> Forms setting </q-item-section>
              </q-item>

              <q-item clickable v-ripple @click="$goto('workspace_board.intergration')">
                <q-item-section avatar>
                  <q-icon size="xs" name="extension" />
                </q-item-section>

                <q-item-section> Intergrations </q-item-section>
              </q-item>

              <q-separator class="q-my-sm" />
            </template>

            <q-item clickable v-ripple @click="themeSelectVisible = true">
              <q-item-section avatar>
                <q-icon size="xs" name="dark_mode" />
              </q-item-section>

              <q-item-section> Theme </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-item>
    </template>

    <q-item clickable v-ripple @click="$goto('help')" :active="activeRouteKey === 'help'">
      <q-item-section avatar>
        <q-icon size="xs" name="help_outline" />
      </q-item-section>

      <q-item-section> Help </q-item-section>
    </q-item>
  </q-list>
  <SelectThemeModal
    v-if="themeSelectVisible"
    :modalVisible="themeSelectVisible"
    @update:closeModal="themeSelectVisible = false"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import SelectThemeModal from '../common/theme/SelectThemeModal.vue'

@Options({
  components: {
    SelectThemeModal,
  },
})
export default class AppSidebarLeftSetting extends Vue {
  @Prop()
  miniState = false

  themeSelectVisible = false

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get activeRouteKey() {
    return this.$route.params?.id || this.$route.meta?.menuKey
  }
}
</script>
