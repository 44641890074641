import gql from 'graphql-tag'

const baseReturn = `
  _id
  workspaceId
  projectId
  title
  description
  creatorId
  customFieldsVal
  attachments
  state
  position
  seenUserIds
  commentCount
  creator {
    _id
    displayName
    photoURL
  }
  updatedAt
  createdAt
`

const TASK_GQL = {
  LIST_QUERY: gql`
    query task_list($projectId: String!) {
      task_list(projectId: $projectId) {
        ${baseReturn}
      }
    }
  `,
  LIST_OF_GUEST_QUERY: gql`
    query task_of_guest_list {
      task_of_guest_list {
        ${baseReturn}
      }
    }
  `,
  SINGLE_QUERY: gql`
    query task_single($id: String!) {
      task_single(id: $id) {
        ${baseReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation task_add($projectId: String!, $input: TaskInput!) {
      task_add(projectId: $projectId, input: $input) {
        ${baseReturn}
      }
    }
  `,
  UPDATE_MUTATION: gql`
    mutation task_update($id: String!, $input: TaskInput!) {
      task_update(id: $id, input: $input) {
        ${baseReturn}
      }
    }
  `,
  UPDATE_SEEN_MUTATION: gql`
    mutation task_update_seen($id: String!) {
      task_update_seen(id: $id) {
        ${baseReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation task_delete($id: String!) {
      task_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
  CHANGE_SUBSCRIBE: gql`
    subscription task_changed($projectId: String!) {
      task_changed(projectId: $projectId) {
        ${baseReturn}
        changeType
      }
    }
  `,
  CHANGE_OF_GUEST_SUBSCRIBE: gql`
    subscription task_of_guest_changed {
      task_of_guest_changed {
        ${baseReturn}
        changeType
      }
    }
  `,
}

export default TASK_GQL
