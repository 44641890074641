import { CustomFieldValue } from '@/utils/types'

export enum ECustomFieldType {
  input = 'input',
  number = 'number',
  textarea = 'textarea',
  dropdown = 'dropdown',
  checkbox = 'checkbox',
  radio = 'radio',
  member = 'member',
  label = 'label',
  datepicker = 'datepicker',
  upload = 'upload',
}

export interface ICustomFieldOption {
  color: string
  label: string
  value: string
}

export interface ICustomFieldChange {
  field: ICustomField
  value: CustomFieldValue
}

export enum ECustomFieldInputType {
  text = 'text',
  number = 'number',
}

export enum ECustomFieldFixSymbolType {
  prefix = 'prefix',
  suffix = 'suffix',
}

export interface ICustomFieldFormatStyle {
  type: ECustomFieldInputType
  mask?: string
  precision?: number
  fixSymbol?: string
  fixSymbolPosition?: 'prefix' | 'suffix'
}

export interface ICustomField {
  type?: ECustomFieldType
  label?: string
  name?: string
  defaultValue?: string | number | boolean
  options?: ICustomFieldOption[]
  rules?: IValidationRule[]
  placeholder?: string
  style?: Record<string, string>
  class?: string
  multiSelect?: boolean
  formatStyle?: ICustomFieldFormatStyle
}

/* Validate rule */
export enum EValidationRuleName {
  isRequired = 'isRequired',
}

export interface IValidationRule {
  ruleName: EValidationRuleName
  args?: string[]
  errorMessage?: string
}
