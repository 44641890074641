/* eslint-disable */
import { register } from 'register-service-worker'

if (process.env.NODE_ENV !== 'dev1') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      if (window.confirm('A new version is available, update now?')) {
        const worker = registration.waiting
        if (worker) {
          worker.postMessage({ type: 'SKIP_WAITING' })

          worker.addEventListener('statechange', (e: any) => {
            if (e?.target?.state === 'activated') {
              window.location.reload();
            }
          })
        }
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
