import { App } from 'vue'
import { format as formatNumeric, CurrencyDisplay } from '@sangopkg/vue-currency-input'
import { ECustomFieldInputType, ICustomFieldFormatStyle } from '@/components/custom-field/custom-field-model'

const getNumericFieldPreviewValue = (value: number, formatStyle: ICustomFieldFormatStyle) => {
  return formatNumeric(value, {
    useCustomFixSymbol: true,
    currencyDisplay: CurrencyDisplay.hidden,
    hideGroupingSeparatorOnFocus: false,
    customFixSymbol: formatStyle.fixSymbol,
    customFixSymbolPosition: formatStyle.fixSymbolPosition,
    precision: formatStyle.precision,
  })
}

export default {
  install: (app: App) => {
    const isNumericField = (formatStyle?: ICustomFieldFormatStyle) => {
      if (formatStyle) {
        return formatStyle.type === ECustomFieldInputType.number
      }

      return false
    }

    const formatFieldValue = (value: string, formatStyle: ICustomFieldFormatStyle) => {
      if (value) {
        if (formatStyle && isNumericField(formatStyle)) {
          return getNumericFieldPreviewValue(+value, formatStyle)
        }

        return value
      }

      return '-'
    }

    const filters = {
      formatFieldValue,
      isNumericField,
    }

    app.config.globalProperties.$filters = filters
    app.provide('filters', filters)
  },
}
