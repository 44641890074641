import { APP_USER_STORE_KEY } from '@/constants/vars'
import { localStore } from '@/utils/localstore'
import { App } from 'vue'

export default {
  install: (app: App) => {
    const supportedThemes = [
      {
        name: 'dark',
        title: 'Dark theme',
      },
      {
        name: 'light',
        title: 'Light theme',
      },
    ]

    const setTheme = (name: string) => {
      if (!document.body) {
        return
      }

      for (const theme of supportedThemes) {
        document.body.classList.remove(`app-theme--${theme.name}`)
      }

      localStore.setItem(APP_USER_STORE_KEY.SELECTED_THEME, name)
      document.body.classList.add(`app-theme--${name}`)
    }

    const selectedTheme = () => {
      return localStore.getItem(APP_USER_STORE_KEY.SELECTED_THEME) || 'dark'
    }

    const setInitTheme = () => {
      setTheme(selectedTheme())
    }

    const methods = {
      setTheme,
      setInitTheme,
      themes: supportedThemes,
      selected: selectedTheme,
    }

    app.config.globalProperties.$theme = methods
    app.provide('theme', methods)
  },
}
