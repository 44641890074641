import cloneDeep from 'lodash/cloneDeep'
import TaskApi from '@/components/task/task-api'

import { graphqlChangedHandler } from '@/utils/graphql'
import { ITask, TaskModel } from '@/components/task/task-model'
import { ACTION_TASK } from './task-actions'

type TState = {
  items: Record<string, TaskModel[]>
  itemsOfGuest: TaskModel[]
  item: TaskModel | null
  loading: boolean
}

const initState: TState = {
  items: {},
  itemsOfGuest: [],
  item: null,
  loading: false,
}

export default {
  state: initState,
  getters: {
    selectedTask: (state: TState) => {
      return state.item
    },
    tasks: (state: TState) => {
      return (projectId: string) => {
        return state.items[projectId] || []
      }
    },
    tasksOfGuest: (state: TState) => state.itemsOfGuest,
  },
  mutations: {
    [ACTION_TASK.SET_LOADING]: (state: TState) => {
      state.loading = true
    },
    // [Tasks in project]
    [ACTION_TASK.SET_ITEMS]: (state: TState, payload: { items: TaskModel[], projectId: string }) => {
      state.loading = false
      state.items[payload.projectId] = payload.items
    },
    [ACTION_TASK.SET_NEW_ITEM]: (state: TState, { item }: { item: TaskModel }) => {
      if (!item.projectId) {
        return
      }

      state.items[item.projectId] = graphqlChangedHandler<TaskModel>(state.items[item.projectId], item)
    },
    // [Tasks in workspace]
    [ACTION_TASK.SET_ITEMS_OF_GUEST]: (state: TState, payload: { items: TaskModel[] }) => {
      state.itemsOfGuest = payload.items
      state.loading = false
    },
    [ACTION_TASK.SET_NEW_ITEM_OF_GUEST]: (state: TState, { item }: { item: TaskModel }) => {
      state.itemsOfGuest = graphqlChangedHandler<TaskModel>(
        state.itemsOfGuest,
        item
      )
    },
    // [Set selected task]
    [ACTION_TASK.SET_ITEM]: (state: TState, { item }: { item: TaskModel }) => {
      state.item = cloneDeep(item)
    },
    [ACTION_TASK.CLEAR]: (state: TState) => {
      state.item = initState.item
      state.items = initState.items
      state.loading = initState.loading
    },
  },
  actions: {
    // [Task in project]
    [ACTION_TASK.LOAD_ITEMS]: async ({ commit, state }, payload: { projectId: string }) => {
      commit(ACTION_TASK.SET_LOADING)
      let tasks = state.items[payload.projectId]
      if (!tasks) {
        tasks = await TaskApi.project(payload.projectId).list()
      }

      commit(ACTION_TASK.SET_ITEMS, { projectId: payload.projectId, items: tasks || [] })
    },
    // [Task in workspace]
    [ACTION_TASK.LOAD_ITEMS_OF_GUEST]: async ({ commit }) => {
      commit(ACTION_TASK.SET_LOADING)
      const tasks = await TaskApi.listOfGuest()
      commit(ACTION_TASK.SET_ITEMS_OF_GUEST, { items: tasks || [] })
    },
    // eslint-disable-next-line
    [ACTION_TASK.ADD]: async ({ commit }, payload: { input: ITask, projectId: string }) => {
      return await TaskApi.project(payload.projectId).add(payload.input)
    },
    // eslint-disable-next-line
    [ACTION_TASK.UPDATE]: async ({ commit }, payload: { task: ITask }) => {
      return await TaskApi.update(payload.task)
    },
    // eslint-disable-next-line
    [ACTION_TASK.DELETE]: async ({ commit }, payload: { id: string }) => {
      return await TaskApi.delete(payload.id)
    },
  },
}
