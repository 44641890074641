import gql from 'graphql-tag'
const baseReturn = `
  _id
  displayName
  username
  photoURL
  email
  uid
`
const PROFILE_GQL = {
  ME_QUERY: gql`
    query me {
      me {
        ${baseReturn}
      }
    }
  `,

  LOGIN_MUTATION: gql`
    mutation user_login($input: LoginInput!) {
      user_login (
        input: $input
      ) {
        token
        user {
          ${baseReturn}
        }
      }
    }
  `,

  LOGIN_GUEST_MUTATION: gql`
    mutation user_login_guest($input: LoginGuestInput!) {
      user_login_guest (
        input: $input
      ) {
        token
        projectId
        user {
          _id
          displayName
        }
      }
    }
  `,

  REGISTER_MUTATION: gql`
    mutation user_register($input: RegisterInput!) {
      user_register (
        input: $input
      ) {
        token
        user {
          ${baseReturn}
        }
      }
    }
  `,

  UPDATE_PROFILE_MUTATION: gql`
    mutation user_profile_update($input: ProfileUpdateInput!) {
      user_profile_update (
        input: $input
      ) {
        ${baseReturn}
      }
    }
  `,

  SOCIAL_LOGIN_MUTATION: gql`
    mutation user_login_social ($input: RegisterInput!) {
      user_login_social(
        input: $input
      ) {
        token
        user {
          ${baseReturn}
        }
      }
    }
  `,
}
export default PROFILE_GQL
