import { render } from "./AppSidebarLeft.vue?vue&type=template&id=0c8d8ac0"
import script from "./AppSidebarLeft.vue?vue&type=script&lang=ts"
export * from "./AppSidebarLeft.vue?vue&type=script&lang=ts"

import "./AppSidebarLeft.vue?vue&type=style&index=0&id=0c8d8ac0&lang=scss"
script.render = render

export default script
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QList from 'quasar/src/components/item/QList.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QScrollArea,QList,QBtn,QItem});
