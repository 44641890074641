export const ACTION_INVITATION = {
  LOAD_ITEMS: 'INVITATION/LOAD_ITEMS',
  LOAD_ITEM: 'INVITATION/LOAD_ITEM',
  ADD: 'INVITATION/ADD',
  UPDATE: 'INVITATION/UPDATE',
  DELETE: 'INVITATION/DELETE',
  SET_ITEMS: 'INVITATION/SET_ITEMS',
  SET_NEW_ITEM: 'INVITATION/SET_NEW_ITEM',
  SET_ITEM: 'INVITATION/SET_ITEM',
  CLEAR: 'INVITATION/CLEAR',
}
