import { ECustomFieldType } from '@/components/custom-field/custom-field-model'
import { EUserRole } from '@/components/user/user-model'
import { EWorkflowType, IWorkflowState } from '@/components/workflow/workflow-model'

export const SLACK_AUTH_URL = 'https://slack.com/oauth/authorize?scope=incoming-webhook,commands'

export const APP_USER_STORE_KEY = {
  AUTH_TOKEN: 'auth-token',
  SELECTED_AUTH_SLACK_PROJECT_ID: 'selected-auth-slack-project-id',
  SELECTED_WORKSPACE: 'selected-workspace-id',
  SELECTED_PROJECT_IDS: 'selected-project-ids',
  BOARD_VIEW_MODE: 'board-view-mode',
  TASK_FILTERS: 'task-filters',
  LAST_SEEN_ACTIVITY_AT: 'last-seen-activity-at',
  SELECTED_THEME: 'selected-theme',
}

export const APP_ROUTER_STORE_KEY = {
  REDIRECT_LOGIN: 'redirect-login',
  SLACK_REDIRECT_URL: 'slack-redirect-url',
}

export const WORKFLOW_TYPE = [
  {
    label: 'New',
    value: EWorkflowType.new,
  },
  {
    label: 'Doing',
    value: EWorkflowType.doing,
  },
  {
    label: 'Done',
    value: EWorkflowType.done,
  },
]

export const UNKNOWN_WORKFLOW_STATE: IWorkflowState = {
  label: 'Unknown',
  value: 'unknown',
  color: 'grey',
  type: EWorkflowType.new,
  position: 0,
}

export const COLORS_LIST = [
  '#8a2be2',
  '#Bf00ff',
  '#6f2da8',
  '#000080',
  '#03BFFF',
  '#Daa520',
  '#Cd7f32',
  '#A0522d',
  '#E4d008',
  '#9acd32',
  '#E4d96f',
  '#4cbb17',
  '#228b22',
  '#90ee90',
  '#36454f',
  '#002147',
  '#1a1110',
  '#800000',
  '#Ca2c92',
  '#66023c',
  '#Ff6700',
  '#Ffb347',
  '#A94064',
  '#Ffe5b4',
  '#Ff1493',
  '#De5d83',
  '#Eedc82',
  '#F1e1cc',
  '#36454f',
  '#B2beb5',
  '#40404f',
]

export const CUSTOM_FIELD_TYPE_LIST = [
  {
    value: ECustomFieldType.input,
    label: 'Input text (short text)',
  },
  {
    value: ECustomFieldType.textarea,
    label: 'Textarea (long text)',
  },
  {
    value: ECustomFieldType.number,
    label: 'Number',
  },
  {
    value: ECustomFieldType.dropdown,
    label: 'Dropdown select',
  },
  {
    value: ECustomFieldType.checkbox,
    label: 'Checkbox',
  },

  {
    value: ECustomFieldType.datepicker,
    label: 'Datepicker',
  },
  {
    value: ECustomFieldType.upload,
    label: 'File upload',
  },
  {
    value: ECustomFieldType.label,
    label: 'Labels',
  },
  {
    value: ECustomFieldType.radio,
    label: 'Radio',
  },
]

export const CUSTOM_FIELD_DISPLAY_IN_TABLE = [ECustomFieldType.label]

export const ROLES_SELECT = [
  {
    label: 'Owner',
    value: EUserRole.owner,
  },
  {
    label: 'Member',
    value: EUserRole.member,
  },
]
