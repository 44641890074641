<template>
  <div class="text-center">Copyright ©2021 Sango Technologies Inc. All rights reserved.</div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class AppFooter extends Vue {}
</script>
