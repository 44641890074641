<template>
  <q-page-container class="max-w-full-pt">
    <router-view />
  </q-page-container>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
  emits: ['update:collapsed'],
})
export default class AppGuestMode extends Vue {}
</script>
