import store from '@/store'
import logging from '@/utils/logging'
import ACTIVITY_GQL from './activity-graphql'
import { NormApi } from '../../api/norm'
import { ActivityModel } from '@/components/activity/activity-model'
import { ACTION_ACTIVITY } from './activity-actions'

export class ActivityApi extends NormApi<ActivityModel> {
  constructor() {
    super()
    this.setClassRef(ActivityModel)
  }

  list = async (workspaceId?: string): Promise<ActivityModel[] | null> => {
    if (this.apolloSubscibe) {
      this.apolloSubscibe.unsubscribe()
    }

    return this.query({
      query: ACTIVITY_GQL.LIST_QUERY,
      variables: {
        workspaceId,
      },
    })
  }

  subscribeNewActivity = (workspaceId?: string) => {
    logging.debug('Activity watching inititialed.')

    this.subscribe<ActivityModel>({
      query: ACTIVITY_GQL.CHANGE_SUBSCRIBE,
      variables: {
        workspaceId,
      },
      success: (data: ActivityModel) => {
        logging.debug('New activity detected', data)
        store.commit(ACTION_ACTIVITY.SET_LAST_ACTIVITY, { item: data })
      },
    })
  }
}

export default new ActivityApi()
