import gql from 'graphql-tag'

const baseReturn = `
  _id
  title
  description
  permissions
  members {
    _id
    role
    displayName
    username
    photoURL
  }
`

const WORKSPACE_GQL = {
  LIST_QUERY: gql`
    query workspace_list {
      workspace_list {
        ${baseReturn}
      }
    }
  `,
  SINGLE_QUERY: gql`
    query workspace_single($id: String!) {
      workspace_single(id: $id) {
        ${baseReturn}
      }
    }
  `,
  ADD_MUTATION: gql`
    mutation workspace_add($input: WorkspaceInput!) {
      workspace_add(input: $input) {
        ${baseReturn}
      }
    }
  `,
  UPDATE_MUTATION: gql`
    mutation workspace_update($id: String!, $input: WorkspaceInput!) {
      workspace_update(id: $id, input: $input) {
        ${baseReturn}
      }
    }
  `,
  DELETE_MUTATION: gql`
    mutation workspace_delete($id: String!) {
      workspace_delete(id: $id) {
        ${baseReturn}
      }
    }
  `,
}

export default WORKSPACE_GQL
