import { render } from "./NotFound.vue?vue&type=template&id=6d1aaa37&scoped=true"
import script from "./NotFound.vue?vue&type=script&lang=ts"
export * from "./NotFound.vue?vue&type=script&lang=ts"

import "./NotFound.vue?vue&type=style&index=0&id=6d1aaa37&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-6d1aaa37"

export default script
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSeparator,QBtn});
