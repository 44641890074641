export const ACTION_TASK = {
  SET_LOADING: 'TASK/SET_LOADING',
  LOAD_ITEMS: 'TASK/LOAD_ITEMS',
  LOAD_ITEMS_OF_GUEST: 'TASK_COMMENT/LOAD_ITEMS_OF_GUEST',
  LOAD_ITEM: 'TASK/LOAD_ITEM',
  ADD: 'TASK/ADD',
  UPDATE: 'TASK/UPDATE',
  DELETE: 'TASK/DELETE',
  SET_ITEMS: 'TASK/SET_ITEMS',
  SET_ITEMS_OF_GUEST: 'TASK_COMMENT/SET_ITEMS_OF_GUEST',
  SET_NEW_ITEM: 'TASK/SET_NEW_ITEM',
  SET_NEW_ITEM_OF_GUEST: 'TASK/SET_NEW_ITEM_OF_GUEST',
  SET_ITEM: 'TASK/SET_ITEM',
  CLEAR: 'TASK/CLEAR',
}
