export const ACTION_WORKFLOW = {
  LOAD_ITEMS: 'WORKFLOW/LOAD_ITEMS',
  LOAD_ITEM: 'WORKFLOW/LOAD_ITEM',
  ADD: 'WORKFLOW/ADD',
  UPDATE: 'WORKFLOW/UPDATE',
  DELETE: 'WORKFLOW/DELETE',
  SET_ITEMS: 'WORKFLOW/SET_ITEMS',
  SET_NEW_ITEM: 'WORKFLOW/SET_NEW_ITEM',
  SET_ITEM: 'WORKFLOW/SET_ITEM',
  CLEAR: 'WORKFLOW/ACTION_PROJECT',
}
