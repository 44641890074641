import store from '@/store'
import BaseModel from '../../models/BaseModel'
import { EUserRole, IUserWithPermission, UserModel } from '@/components/user/user-model'

export interface ILabel {
  label: string
  value: string
  color: string
}

export interface IWorkspacePermission {
  userId: string
  role: EUserRole
}

export interface IWorkspace {
  _id?: string
  title?: string
  description?: string
  permissions?: IWorkspacePermission[]
}

export class WorkspaceModel extends BaseModel {
  _id?: string
  title?: string
  description?: string

  permissions?: IWorkspacePermission[]
  members?: IUserWithPermission[]

  createdAt?: string
  updatedAt?: string

  get loggedUser(): UserModel {
    return store.getters.userInfo || store.getters.guestInfo
  }

  get isMember() {
    return this.permissions?.find((per: IWorkspacePermission) => per.userId === this.loggedUser?._id)
  }

  get isOwner() {
    return this.permissions?.find((per: IWorkspacePermission) => {
      return per.userId === this.loggedUser?._id && per.role === EUserRole.owner
    })
  }

  get canRead() {
    return this.isMember
  }

  get canWrite() {
    return this.isOwner
  }

  serialize() {
    return {
      _id: this._id,
      title: this.title,
      description: this.description,
    }
  }
}
