export const ACTION_PROJECT = {
  LOAD_ITEMS: 'PROJECT/LOAD_ITEMS',
  LOAD_ITEM: 'PROJECT/LOAD_ITEM',
  ADD: 'PROJECT/ADD',
  UPDATE: 'PROJECT/UPDATE',
  DELETE: 'PROJECT/DELETE',
  SET_ITEMS: 'PROJECT/SET_ITEMS',
  SET_NEW_ITEM: 'PROJECT/SET_NEW_ITEM',
  SET_ITEM: 'PROJECT/SET_ITEM',
  // SET_SELECTED_PROJECT_IDS: 'PROJECT/SET_SELECTED_PROJECT_IDS',
  MERGE_GUEST: 'PROJECT/MERGE_GUEST',
  CLEAR: 'PROJECT/ACTION_PROJECT',
}
