<template>
  <div class="full-width full-height text-center">
    <q-spinner-puff size="4em" class="absolute absolute-center" />
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'

@Options({
  components: {},
})
export default class CLoading extends Vue {}
</script>
