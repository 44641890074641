import { AppApolloClient } from '@/plugins/apollo'
import { EChangeType, IGraphqlError } from '@/utils/types'
import Notify from 'quasar/src/plugins/Notify.js';
import { ApolloQueryResult, FetchResult } from '@apollo/client'
import { errorHandler, parseError } from '@/utils/errorHandler'

export const baseUrl = `${process.env.VUE_APP_API_ENDPOINT}`
const apolloClient = new AppApolloClient()
/* eslint-disable */
export class NormApi<T> {
  $apollo = apolloClient.linkClient()
  $apolloWS: any = null
  classRef!: any

  $q = {
    notify: Notify.create,
  }
  apolloSubscibe: any = null

  setWS() {
    if (apolloClient.getAuthToken() && !this.$apolloWS) {
      this.$apolloWS = apolloClient.wsClient()
    }
  }

  setClassRef(classRef: any) {
    this.classRef = classRef
  }

  responseHandler(response: any) {
    return response?.data?.data || response?.data || response
  }

  parseDataModel(data: any, keyResponse?: string) {
    if (!this.classRef) {
      return data
    }

    if (Array.isArray(data)) {
      const list: T[] = []
      for (const item of data) {
        list.push(new this.classRef(item))
      }

      return list
    }

    if (data) {
      if (keyResponse && !data.changeType) {
        data.changeType = this.getChangeType(keyResponse)
      }

      return new this.classRef(data)
    }
  }

  getChangeType(keyResponse: string) {
    if (keyResponse.indexOf('_add') >= 0) {
      return EChangeType.added
    }

    if (keyResponse.indexOf('_update') >= 0) {
      return EChangeType.modified
    }

    if (keyResponse.indexOf('_delete') >= 0) {
      return EChangeType.removed
    }
  }

  query<T>(args: {
    query: any
    variables?: any
    fetchPolicy?: string
    success?: Function
    error?: Function
  }): Promise<T | null> {
    return new Promise(resolve => {
      return this.$apollo
        .query({ query: args.query, variables: args.variables, fetchPolicy: 'no-cache' })
        .then((resp: ApolloQueryResult<any>) => {
          if (!resp?.data) {
            return resolve(null)
          }
          const keyResponse = args.query.definitions[0].name.value
          const data = resp?.data[keyResponse]

          const result = this.parseDataModel(data, keyResponse)
          resolve(result)

          if (args.success) {
            args.success(result)
          }
        })
        .catch((error: IGraphqlError) => {
          this.errorHandler(error)
          if (args.error) {
            args.error(parseError(error))
          }
        })
        .finally(() => {
          resolve(null)
        })
    })
  }

  mutation<T>(args: { query: any; variables?: any; success?: Function; error?: Function }): Promise<T | null> {
    return new Promise(resolve => {
      this.$apollo
        .mutate({ mutation: args.query, variables: args.variables })
        .then((resp: FetchResult<any>) => {
          if (!resp?.data) {
            return resolve(null)
          }
          const keyResponse = args.query.definitions[0].name.value
          const data = resp?.data[keyResponse] as T
          const result = this.parseDataModel(data, keyResponse)

          resolve(result)
          if (args.success) {
            args.success(result)
          }
        })
        .catch((error: IGraphqlError) => {
          this.errorHandler(error)
          if (args.error) {
            args.error(parseError(error))
          }
        })
        .finally(() => {
          resolve(null)
        })
    })
  }

  subscribe<T>(args: { query: any; variables?: any; success?: Function; error?: Function }) {
    this.setWS()
    if (!this.$apolloWS) {
      return Promise.resolve(null)
    }

    const that = this
    return this.$apolloWS
      .subscribe({
        query: args.query,
        variables: args.variables,
      })
      .subscribe({
        next(resp: ApolloQueryResult<any>) {
          const keyResponse = args.query.definitions[0].name.value
          const data = resp?.data[keyResponse] as T
          if (!data) {
            return false
          }

          const result = that.parseDataModel(data)

          if (args.success) {
            args.success(result)
          }
        },
        error(error: IGraphqlError) {
          errorHandler(error)
          if (args.error) {
            args.error(parseError(error))
          }
        },
      })
  }

  errorHandler(error: IGraphqlError) {
    errorHandler(error)
  }
}
