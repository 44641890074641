import { render } from "./ActivityItem.vue?vue&type=template&id=27a9edc6"
import script from "./ActivityItem.vue?vue&type=script&lang=ts"
export * from "./ActivityItem.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection});qInstall(script, 'directives', {Ripple});
