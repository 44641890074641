import store from '@/store'
import BaseModel from '@/models/BaseModel'
import { EUserRole, IUserWithPermission, UserModel } from '@/components/user/user-model'
import { IWorkspacePermission, WorkspaceModel } from '../workspace/workspace-model'
import { IForm } from '../form/form-model'
import { IWorkflow } from '../workflow/workflow-model'

export interface IProjectPermission {
  userId: string
  role: EUserRole
}

export interface IProject {
  _id?: string
  workspaceId?: string
  title?: string
  description?: string

  color?: string
  permissions?: IProjectPermission[]
  formId?: string
  workflowId?: string
}

export class ProjectModel extends BaseModel {
  _id?: string
  workspaceId?: string
  title?: string
  description?: string

  color?: string
  permissions?: IProjectPermission[]
  members?: IUserWithPermission[]

  formId?: string
  form?: IForm

  workflowId?: string
  workflow?: IWorkflow

  isAllowGuestPost?: boolean
  createdAt?: string
  updatedAt?: string

  get workspace(): WorkspaceModel | undefined {
    const workspaces: WorkspaceModel[] = store.getters.workspaces
    if (workspaces?.length) {
      return workspaces.find(workspace => workspace._id === this.workspaceId)
    }

    return undefined
  }

  get loggedUser(): UserModel {
    return store.getters.userInfo || store.getters.guestInfo
  }

  get isMember() {
    return this.workspace?.permissions?.find((per: IWorkspacePermission) => per.userId === this.loggedUser?._id)
  }

  get isOwner() {
    return this.workspace?.permissions?.find((per: IWorkspacePermission) => {
      return per.userId === this.loggedUser?._id && per.role === EUserRole.owner
    })
  }

  get canRead() {
    return this.isMember
  }

  get canWrite() {
    return this.isOwner
  }

  sertialize(): IProject {
    return {
      _id: this._id,
      workspaceId: this.workspaceId,
      formId: this.formId,
      workflowId: this.workflowId,
      title: this.title,
      description: this.description,
      color: this.color,
      permissions: this.permissions || [],
    }
  }
}
