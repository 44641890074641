import { IGraphqlError } from './types'
import Notify from 'quasar/src/plugins/Notify.js';
import i18n from '@/plugins/i18n'

export const ERROR_CODE = {
  MAX_TICKET_PER_DAY: '10009',
  MAX_TICKET_PER_WEEK: '10010',
  EXPIRED_GUEST_LINK: '10011',
}
export interface ISerializedError {
  errorCode: string
  message: string
}

export function errorHandler(error: IGraphqlError | string) {
  let message: string
  if (typeof error === 'string') {
    message = error
  } else {
    message = error.message
  }
  Notify.create({
    message: i18n.global.t(`errors.${message}`),
    color: 'negative',
  })
}

export function parseError(error: IGraphqlError): ISerializedError {
  const errorCode = error.message
  return {
    errorCode,
    message: error.message,
  }
}
