import GUEST_URL_GQL from '@/components/guest-url/guest-url-graphql'
import { GuestUrlModel, IGuestUrl } from '@/components/guest-url/guest-url-model'
import { IGraphqlIgnoreFields } from '@/utils/types'
import { NormApi } from '../../api/norm'

export class GuestUrlApi extends NormApi<GuestUrlModel> {
  workspaceId = ''

  constructor() {
    super()
    this.setClassRef(GuestUrlModel)
  }

  workspace(workspaceId: string) {
    this.workspaceId = workspaceId
    return this
  }

  list = async (): Promise<GuestUrlModel[] | null> => {
    if (this.apolloSubscibe) {
      this.apolloSubscibe.unsubscribe()
    }

    return this.query({
      query: GUEST_URL_GQL.LIST_QUERY,
      variables: {
        workspaceId: this.workspaceId,
      },
    })
  }

  single = async (id: string): Promise<GuestUrlModel | null> => {
    return this.query({
      query: GUEST_URL_GQL.SINGLE_QUERY,
      variables: {
        id: id,
      },
    })
  }

  add = async (payload: IGuestUrl): Promise<GuestUrlModel | null> => {
    return this.mutation({
      query: GUEST_URL_GQL.ADD_MUTATION,
      variables: {
        input: payload,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  update = async (payload: IGuestUrl & IGraphqlIgnoreFields): Promise<GuestUrlModel | null> => {
    // eslint-disable-next-line
    const { _id, changeType, token, workspaceId, ...updateData } = payload
    return this.mutation({
      query: GUEST_URL_GQL.UPDATE_MUTATION,
      variables: {
        id: _id,
        input: updateData,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  refresh = async (payload: IGuestUrl & IGraphqlIgnoreFields): Promise<GuestUrlModel | null> => {
    // eslint-disable-next-line
    const { _id } = payload
    return this.mutation({
      query: GUEST_URL_GQL.REFRESH_MUTATION,
      variables: {
        id: _id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Saved!',
        })
      },
    })
  }

  delete = async (id: string): Promise<IGuestUrl | null> => {
    return this.mutation({
      query: GUEST_URL_GQL.DELETE_MUTATION,
      variables: {
        id,
      },
      success: () => {
        this.$q.notify({
          type: 'positive',
          message: 'Deleted!',
        })
      },
    })
  }
}

const guestUrlApi = new GuestUrlApi()
export default guestUrlApi
