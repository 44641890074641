class Logging {
  debug(...args: unknown[]) {
    if (!this.shouldShowDebug) {
      return
    }

    console.log(`%c[DEBUG] ${process.env.VUE_APP_NAME}`, 'background: #222; color: #bada66', ...args)
  }

  debugRender(...args: unknown[]) {
    if (!this.shouldShowDebug) {
      return
    }

    console.log('%c[DEBUG Rendered]', 'background: yellow; color: green', ...args)
  }

  get shouldShowDebug() {
    return process.env.NODE_ENV !== 'production'
  }
}

export default new Logging()
