
import { Vue, Options } from 'vue-class-component'
import { ACTION_COMMON } from '@/store/actions'
import { IProject, ProjectModel } from '@/components/project/project-model'
import { ACTION_PROJECT } from '../project-actions'

@Options({
  components: {},
})
export default class ProjectMixin extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId as string
  }

  get projects(): ProjectModel[] {
    if (this.workspaceId) {
      return this.$store.getters.projects(this.workspaceId)
    }

    return []
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  async getProject(projectId: string) {
    const project = await this.$store.dispatch(ACTION_PROJECT.LOAD_ITEM, {
      workspaceId: this.workspaceId,
      id: projectId,
    })

    if (!project) {
      this.$store.dispatch(ACTION_COMMON.SET_NOT_FOUND)
    }

    return project
  }

  async getProjects() {
    if (!this.workspaceId || !this.isAuth) {
      return
    }

    return this.$store.dispatch(ACTION_PROJECT.LOAD_ITEMS, { workspaceId: this.workspaceId })
  }

  async addProject(project: IProject) {
    return this.$store.dispatch(ACTION_PROJECT.ADD, { project, workspaceId: this.workspaceId })
  }

  async updateProject(project: IProject) {
    return this.$store.dispatch(ACTION_PROJECT.UPDATE, project)
  }

  async removeProject(project: IProject) {
    return this.$store.dispatch(ACTION_PROJECT.DELETE, { id: project._id })
  }
}
