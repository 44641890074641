import { ECustomFieldType, ICustomField, EValidationRuleName } from '@/components/custom-field/custom-field-model'

export const taskFieldsDisplayInTable = (otherCustomfields?: ICustomField[]): ICustomField[] => {
  return [
    {
      label: 'Title',
      name: 'title',
    },
    ...(otherCustomfields || []),
    {
      label: 'Creator',
      name: 'creator',
    },
    {
      label: 'Last updated',
      name: 'updatedAt',
    },
  ]
}

export const taskFieldsStaticForm: ICustomField[] = [
  {
    label: 'Title',
    name: 'title',
    rules: [{ ruleName: EValidationRuleName.isRequired }],
    placeholder: 'task title',
    class: 'text-h5 text-weight-medium',
    type: ECustomFieldType.input,
  },
]
