<template>
  <q-btn no-caps padding="2px 10px" v-if="isAuth">
    <UserAvatar :user="userInfo" :size="30" />
    <a class="q-ml-xs">
      {{ userInfo.displayName }}
    </a>

    <q-menu fit anchor="top left" self="bottom left" class="q-py-sm">
      <q-list dense>
        <q-item clickable v-close-popup @click="$goto('profile_setting')">
          <q-item-section avatar>
            <q-icon name="manage_accounts" />
          </q-item-section>
          <q-item-section> Profile setting </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="logout">
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section> Logout </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { UserModel } from '@/components/user/user-model'
import { ACTION_PROFILE } from '@/components/user/profile-actions'
import { errorHandler } from '@/utils/errorHandler'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'

@Options({
  components: { UserAvatar },
})
export default class AppTopbarProfile extends Vue {
  @Prop()
  miniState = false

  themeSelectVisible = false

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  logout() {
    try {
      this.$store.dispatch(ACTION_PROFILE.LOGOUT)
      this.$goto('login')
    } catch (error) {
      errorHandler(error)
    }
  }
}
</script>
