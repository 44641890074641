
import { Options, mixins } from 'vue-class-component'
import { UserModel } from '@/components/user/user-model'
import { Watch } from 'vue-property-decorator'
import AppSidebarLeft from '@/components/layout/AppSidebarLeft.vue'
import ProjectMixin from '../project/mixins/ProjectMixin.vue'
import WorkspaceMixin from '../workspace/mixins/WorkspaceMixin.vue'
import ProfileMixin from '../account/mixins/ProfileMixin.vue'
import WorkspaceSwitchBox from '../workspace/ui/WorkspaceSwitchBox.vue'
import AppTopbarProfile from '../account/ui/AppTopbarProfile.vue'
import ActivityButton from '../activity/ui/ActivityButton.vue'

@Options({
  components: {
    ActivityButton,
    AppTopbarProfile,
    WorkspaceSwitchBox,
    AppSidebarLeft,
  },
  emits: ['update:collapsed'],
})
export default class AppHasSidebarLeft extends mixins(ProfileMixin, WorkspaceMixin, ProjectMixin) {
  leftDrawerOpen = true
  get alreadyGetProfileOnInit() {
    return this.$store.getters.alreadyGetProfileOnInit
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get activeRouteKey() {
    return this.$route.meta?.menuKey
  }

  @Watch('workspaceId', { immediate: true })
  @Watch('isAuth')
  async workspaceIdChanged() {
    if (!this.workspaceId || !this.isAuth) {
      return
    }

    await this.getProjects()
  }

  @Watch('$route.params')
  @Watch('alreadyGetProfileOnInit')
  async onRouterChange() {
    if (this.alreadyGetProfileOnInit && !this.isAuth) {
      return this.$goto('login')
    }
  }

  gotoUserProfile() {
    this.$goto('user_profile_view', { id: this.userInfo._id })
  }

  gotoEditProfile() {
    this.$goto('user_profile_edit')
  }

  toggleLeftDrawer() {
    this.leftDrawerOpen = !this.leftDrawerOpen
  }

  gotoHome() {
    this.$goto('home')
  }

  async created() {
    this.$theme.setInitTheme()
    // [InitAuthMixin] mixins
    await this.fetchMe()
  }
}
