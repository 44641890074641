import { ICustomField } from '../custom-field/custom-field-model'
import BaseModel from '../../models/BaseModel'

export interface IForm {
  _id?: string
  workspaceId?: string
  title?: string
  description?: string
  position?: number
  fields?: ICustomField[]
}

export class FormModel extends BaseModel {
  _id?: string
  workspaceId?: string
  title?: string
  description?: string
  position?: number
  fields?: ICustomField[]

  sertialize(): IForm {
    return {
      _id: this._id,
      workspaceId: this.workspaceId,
      title: this.title,
      description: this.description,
      position: this.position,
      fields: this.fields,
    }
  }
}
