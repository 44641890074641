<template>
  <q-item v-if="activity" clickable v-ripple @click="gotoActivityDetail(activity)">
    <q-item-section avatar top>
      <UserAvatar :user="activity.user" :size="40" />
    </q-item-section>
    <q-item-section class="q-mt-xs" caption>
      <div class="q-gutter-xs">
        <span>{{ activity.user.displayName }}</span>
        <span class="text-grey-6">{{ getActivityAction(activity) }}</span>
        <template v-if="activity.task">
          <span>{{ activity.task.title }}</span>
        </template>
        <template v-if="!$guestToken() && activity.project">
          <span class="text-grey-6">on</span>
          <span class="text-grey-6">{{ activity.project.title }}</span>
        </template>
        <div class="text-grey">
          {{ formatDateTime(activity.createdAt) }}
        </div>
      </div>
    </q-item-section>
  </q-item>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { ActivityModel, EActivityAction } from '@/components/activity/activity-model'
import { Prop } from 'vue-property-decorator'
import { formatDateTime } from '@/utils/helpers'

import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import CLoading from '@/components/common/ui/CLoading.vue'

@Options({
  components: { UserAvatar, CLoading },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class ActivityItem extends Vue {
  @Prop()
  activity!: ActivityModel

  formatDateTime = formatDateTime

  getActivityAction(activity: ActivityModel) {
    const mappingAction = {
      [EActivityAction.createdTask]: 'created new task',
      [EActivityAction.updatedTask]: 'updated task',
      [EActivityAction.addedComment]: 'added comment',
    }

    return mappingAction[activity.action as EActivityAction]
  }

  gotoActivityDetail(activity: ActivityModel) {
    if (this.$guestToken()) {
      return this.$goto('guest_page.tasks', {
        taskId: activity.taskId,
      })
    }

    this.$goto('workspace_board.tasks', {
      workspaceId: activity.workspaceId,
      taskId: activity.taskId,
      projectId: activity.projectId,
    })
  }
}
</script>
