
import { Vue, Options } from 'vue-class-component'
import { ACTION_COMMON } from '@/store/actions'
import { IForm } from '@/components/form/form-model'
import { ACTION_FORM } from '../form-actions'

@Options({
  components: {},
})
export default class FormMixin extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId
  }

  async getForm(formId: string) {
    const form = await this.$store.dispatch(ACTION_FORM.LOAD_ITEM, {
      id: formId,
    })

    if (!form) {
      this.$store.dispatch(ACTION_COMMON.SET_NOT_FOUND)
    }

    return form
  }

  async getForms() {
    if (!this.workspaceId) {
      return
    }

    return this.$store.dispatch(ACTION_FORM.LOAD_ITEMS, { workspaceId: this.workspaceId })
  }

  async addForm(form: IForm) {
    return this.$store.dispatch(ACTION_FORM.ADD, { form, workspaceId: this.workspaceId })
  }

  async updateForm(form: IForm) {
    return this.$store.dispatch(ACTION_FORM.UPDATE, form)
  }

  async removeForm(form: IForm) {
    return this.$store.dispatch(ACTION_FORM.DELETE, { id: form._id })
  }
}
