<template>
  <q-btn
    round
    dense
    padding="2px"
    :style="{ background: value, color: 'white' }"
    icon="palette"
    menu-anchor="top left"
    menu-self="top left"
  >
    <q-menu>
      <q-color v-model="value" no-header no-footer default-view="palette" :palette="COLORS_LIST"></q-color>
    </q-menu>
  </q-btn>
</template>
<script lang="ts">
import { COLORS_LIST } from '@/constants/vars'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {},
  emits: ['update:modelValue'],
})
export default class ColorPickerDropdown extends Vue {
  @Prop({ default: COLORS_LIST[0] })
  modelValue!: string

  selectedColor = COLORS_LIST[0]
  COLORS_LIST = COLORS_LIST

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  onSelect(color: string) {
    this.value = color
  }
}
</script>
