import BaseModel from '../../models/BaseModel'
import { IProject } from '../project/project-model'

export interface IGuestUrlSetting {
  ticketPerDay?: number
  ticketPerWeek?: number
  expiredDate?: Date
}
export interface IGuestUrl {
  _id?: string
  workspaceId?: string
  projectId?: string
  token?: string
  displayName?: string
  active?: boolean
  settings?: IGuestUrlSetting
}

export class GuestUrlModel extends BaseModel {
  _id?: string
  workspaceId?: string
  projectId?: string
  project?: IProject

  token?: string
  displayName?: string
  active?: boolean
  settings?: IGuestUrlSetting

  createdAt?: string
  updatedAt?: string

  serialize(): IGuestUrl {
    return {
      _id: this._id,
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      token: this.token,
      displayName: this.displayName,
      active: this.active,
      settings: this.settings,
    }
  }
}
